import store from '@/core/config/vuex';
import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
const OrganizationProfileAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    const organizationLink = store.getters.authenticatedUserPossibleActions.find(link => link.rel === 'get_current_organization_profile');
    if (!organizationLink) {
      GlobalAuthorization.goHomeDueToNoPermissionToAccess();
    }
    const apiUri = decodeURIComponent(organizationLink.href);
    await axios.head(apiUri).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default OrganizationProfileAuthorization;