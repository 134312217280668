import EVENT_PLANNER_MARKETPLACE_ACTIONS from '@/event_booking_domain/event_planner_marketplace/vuex/event-planner-marketplace-actions';
import PurposeCategory from '@/venue_management_domain/admin_purpose/constants/purpose-category';
const EVENT_PLANNER_MARKETPLACE_STORE = {
  state: () => ({
    eventPlannerSearchCriteria: {
      adminPurposeCategory: PurposeCategory.BUSINESS.value,
      rowsPerPage: 20,
      page: 1
    },
    isListView: true
  }),
  mutations: {
    updateEventPlannerSearchCriteria(state, searchCriteria) {
      state.eventPlannerSearchCriteria = searchCriteria;
    },
    updateIsListView(state, isListView) {
      state.isListView = isListView;
    }
  },
  getters: {
    eventPlannerSearchCriteria(state) {
      return state.eventPlannerSearchCriteria;
    },
    isListView(state) {
      return state.isListView;
    }
  },
  actions: {
    updateEventPlannerSearchCriteria({
      commit
    }, searchCriteria) {
      commit(EVENT_PLANNER_MARKETPLACE_ACTIONS.updateEventPlannerSearchCriteria, searchCriteria);
    },
    updateIsListView({
      commit
    }, isListView) {
      commit(EVENT_PLANNER_MARKETPLACE_ACTIONS.updateIsListView, isListView);
    }
  }
};
export default EVENT_PLANNER_MARKETPLACE_STORE;