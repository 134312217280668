import EVENT_ACTIONS from '@/identity_domain/vuex/event/event-actions';
const EVENT_STORE = {
  state: () => ({
    shouldShowEventLimitedFunctionsDialog: false,
    openLicenseRegistrationDialog: false
  }),
  getters: {
    shouldShowEventLimitedFunctionsDialog(state) {
      return state.shouldShowEventLimitedFunctionsDialog;
    },
    openLicenseRegistrationDialog(state) {
      return state.openLicenseRegistrationDialog;
    }
  },
  actions: {
    updateShouldShowEventLimitedFunctionsDialog({
      commit
    }, shouldShowEventLimitedFunctionsDialog) {
      commit(EVENT_ACTIONS.updateShouldShowEventLimitedFunctionsDialog, shouldShowEventLimitedFunctionsDialog);
    },
    updateOpenLicenseRegistrationDialog({
      commit
    }, openLicenseRegistrationDialog) {
      commit(EVENT_ACTIONS.updateOpenLicenseRegistrationDialog, openLicenseRegistrationDialog);
    }
  },
  mutations: {
    updateShouldShowEventLimitedFunctionsDialog(state, shouldShowEventLimitedFunctionsDialog) {
      state.shouldShowEventLimitedFunctionsDialog = shouldShowEventLimitedFunctionsDialog;
    },
    updateOpenLicenseRegistrationDialog(state, openLicenseRegistrationDialog) {
      state.openLicenseRegistrationDialog = openLicenseRegistrationDialog;
    }
  }
};
export default EVENT_STORE;