import GlobalAuthorization from '@/core/authorizations/global-authorization';
const VenueCustomerGroupsAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    const hasPermission = true;
    if (!hasPermission) {
      GlobalAuthorization.goHomeDueToNoPermissionToAccess();
    } else if (next) {
      next();
    }
  }
};
export default VenueCustomerGroupsAuthorization;