import EXHIBITOR_ACTIONS from '@/identity_domain/vuex/exhibitor/exhibitor-actions';
const EXHIBITOR_STORE = {
  state: () => ({
    isInvitingRegisteredExhibitor: false,
    registeredExhibitorEmail: null,
    exhibitorOrganizationLinks: []
  }),
  getters: {
    isInvitingRegisteredExhibitor(state) {
      return state.isInvitingRegisteredExhibitor;
    },
    registeredExhibitorEmail(state) {
      return state.registeredExhibitorEmail;
    },
    exhibitorOrganizationLinks(state) {
      return state.exhibitorOrganizationLinks;
    }
  },
  actions: {
    updateIsInvitingRegisteredExhibitor({
      commit
    }, isInvitingRegisteredExhibitor) {
      commit(EXHIBITOR_ACTIONS.updateIsInvitingRegisteredExhibitor, isInvitingRegisteredExhibitor);
    },
    updateRegisteredExhibitorEmail({
      commit
    }, registeredExhibitorEmail) {
      commit(EXHIBITOR_ACTIONS.updateRegisteredExhibitorEmail, registeredExhibitorEmail);
    },
    updateExhibitorOrganizationLinks({
      commit
    }, links) {
      commit(EXHIBITOR_ACTIONS.updateExhibitorOrganizationLinks, links);
    }
  },
  mutations: {
    updateIsInvitingRegisteredExhibitor(state, isInvitingRegisteredExhibitor) {
      state.isInvitingRegisteredExhibitor = isInvitingRegisteredExhibitor;
    },
    updateRegisteredExhibitorEmail(state, registeredExhibitorEmail) {
      state.registeredExhibitorEmail = registeredExhibitorEmail;
    },
    updateExhibitorOrganizationLinks(state, links) {
      state.exhibitorOrganizationLinks = links;
    }
  }
};
export default EXHIBITOR_STORE;