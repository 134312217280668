const locationEndpoint = '/v1/location';
const LocationAPIs = {
  FETCH_LOCATION(organizationId, locationId) {
    return `${locationEndpoint}/organizations/${organizationId}/venues/${locationId}`;
  },
  TOGGLE_FAVORITE_LOCATION(locationId) {
    return `${locationEndpoint}/favorite/${locationId}`;
  },
  CHECK_EVENT_EXIST_IN_DAY_BLOCK(locationId) {
    return `${locationEndpoint}/${locationId}/dayBlock/used`;
  },
  SEND_LOCATION_MESSAGE(locationId) {
    return `${locationEndpoint}/${locationId}/message`;
  },
  GET_VENUE_COMPLETION_INFORMATION(venueId) {
    return `/v1/location/venue-level/venue-completion-information/${venueId}`;
  }
};
export default LocationAPIs;