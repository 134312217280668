import ADMIN_LANGUAGES_ACTIONS from '@/crm_domain/languages/vuex/admin-languages-actions';
const ADMIN_LANGUAGES_STORE = {
  state: () => ({
    /**
     * list language of admin that is active
     */
    activeLanguages: [],
    /**
     * list all languages of admin
     */
    languages: []
  }),
  mutations: {
    updateActiveLanguages(state, activeLanguages) {
      state.activeLanguages = activeLanguages;
    },
    updateLanguages(state, languages) {
      state.languages = languages;
    }
  },
  getters: {
    activeLanguages(state) {
      return state.activeLanguages;
    },
    allLanguages(state) {
      return state.languages;
    }
  },
  actions: {
    /**
     * update the languages use for normal users
     * @param commit
     * @param activeLanguages
     */
    updateActiveLanguages({
      commit
    }, activeLanguages) {
      commit(ADMIN_LANGUAGES_ACTIONS.updateActiveLanguages, activeLanguages);
    },
    /**
     * update the languages use for admin
     * @param commit
     * @param languages
     */
    updateLanguages({
      commit
    }, languages) {
      commit(ADMIN_LANGUAGES_ACTIONS.updateLanguages, languages);
    }
  }
};
export default ADMIN_LANGUAGES_STORE;