import store from '@/core/config/vuex';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
const offlineEventId = 'offline';
const EventAuthentication = {
  // eslint-disable-next-line no-unused-vars
  authenticate({
    to,
    from,
    next
  }) {
    if (store.getters.isLoggedIn || to.params.eventCollectionId === offlineEventId) {
      next();
    } else {
      store.dispatch(AUTHENTICATION_ACTIONS.openLoginModal, to);
    }
  }
};
export default EventAuthentication;