import VENUES_TO_COMPARE_ACTIONS from '@/event_booking_domain/venue_compare/vuex/venues-to-compare-actions';
const VENUES_TO_COMPARE_STORE = {
  state: () => ({
    comparingVenues: []
  }),
  mutations: {
    updateComparingVenues(state, ids) {
      state.comparingVenues = ids;
    },
    clearComparingVenues(state) {
      state.comparingVenues = [];
    }
  },
  getters: {
    comparingVenues(state) {
      return state.comparingVenues;
    }
  },
  actions: {
    updateComparingVenues({
      commit
    }, ids) {
      commit(VENUES_TO_COMPARE_ACTIONS.updateComparingVenues, ids);
    },
    clearComparingVenues({
      commit
    }) {
      commit(VENUES_TO_COMPARE_ACTIONS.clearComparingVenues);
    }
  }
};
export default VENUES_TO_COMPARE_STORE;