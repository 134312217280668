import SUPPLIER_ACTIONS from '@/supplier_domain/vuex/supplier-actions';
const SupplierStore = {
  state: () => ({
    supplierUserLinks: [],
    supplierId: '',
    supplierOnline: false
  }),
  getters: {
    supplierId(state) {
      return state.supplierId;
    },
    supplierOnline(state) {
      return state.supplierOnline;
    },
    supplierUserLinks(state) {
      return state.supplierUserLinks;
    }
  },
  actions: {
    updateSupplierId({
      commit
    }, supplierId) {
      commit(SUPPLIER_ACTIONS.updateSupplierId, supplierId);
    },
    updateLoggedInSupplier({
      commit
    }, supplierInformation) {
      commit(SUPPLIER_ACTIONS.updateLoggedInSupplier, supplierInformation);
    },
    updateSupplierUserLinks({
      commit
    }, supplierUserLinks) {
      commit(SUPPLIER_ACTIONS.updateSupplierUserLinks, supplierUserLinks);
    },
    updateSupplierOnline({
      commit
    }, supplierOnline) {
      commit(SUPPLIER_ACTIONS.updateSupplierOnline, supplierOnline);
    }
  },
  mutations: {
    updateSupplierId(state, supplierId) {
      state.supplierId = supplierId;
    },
    updateLoggedInSupplier(state, supplierInformation) {
      if (supplierInformation) {
        state.supplierId = supplierInformation.supplierId;
        state.supplierOnline = supplierInformation.online;
      }
    },
    updateSupplierOnline(state, supplierOnline) {
      state.supplierOnline = supplierOnline;
    },
    updateSupplierUserLinks(state, supplierUserLinks) {
      state.supplierUserLinks = supplierUserLinks;
    }
  }
};
export default SupplierStore;