import EVENT_PLANNER_MARKETPLACE_ACTIONS from '@/event_booking_domain/event_planner_marketplace/vuex/event-planner-marketplace-actions';
import PurposeCategory from '@/venue_management_domain/admin_purpose/constants/purpose-category';
var EVENT_PLANNER_MARKETPLACE_STORE = {
  state: function state() {
    return {
      eventPlannerSearchCriteria: {
        adminPurposeCategory: PurposeCategory.BUSINESS.value,
        rowsPerPage: 20,
        page: 1
      },
      isListView: true
    };
  },
  mutations: {
    updateEventPlannerSearchCriteria: function updateEventPlannerSearchCriteria(state, searchCriteria) {
      state.eventPlannerSearchCriteria = searchCriteria;
    },
    updateIsListView: function updateIsListView(state, isListView) {
      state.isListView = isListView;
    }
  },
  getters: {
    eventPlannerSearchCriteria: function eventPlannerSearchCriteria(state) {
      return state.eventPlannerSearchCriteria;
    },
    isListView: function isListView(state) {
      return state.isListView;
    }
  },
  actions: {
    updateEventPlannerSearchCriteria: function updateEventPlannerSearchCriteria(_ref, searchCriteria) {
      var commit = _ref.commit;
      commit(EVENT_PLANNER_MARKETPLACE_ACTIONS.updateEventPlannerSearchCriteria, searchCriteria);
    },
    updateIsListView: function updateIsListView(_ref2, isListView) {
      var commit = _ref2.commit;
      commit(EVENT_PLANNER_MARKETPLACE_ACTIONS.updateIsListView, isListView);
    }
  }
};
export default EVENT_PLANNER_MARKETPLACE_STORE;