import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import AUTHENTICATION_STORE from '@/identity_domain/authentication/vuex/authentication-store';
import BEVERAGE_GROUP_STORE from '@/catering_domain/venue_beverage_group_type/vuex/beverage-group-store';
import ADMIN_LANGUAGES_STORE from '@/crm_domain/languages/vuex/admin-languages-store';
import VENUES_TO_COMPARE_STORE from '@/event_booking_domain/venue_compare/vuex/venues-to-compare-store';
import SEARCH_PAGE_NAVIGATION_STORE from '@/event_booking_domain/search_venues/vuex/search-page-navigation-store';
import SEARCH_PAGE_CRITERIA_STORE from '@/event_booking_domain/search_venues/vuex/search-page-criteria-store';
import VENUE_UPGRADING_WIZARD_STORE from '@/venue_management_domain/venue_upgrade_wizard/vuex/venue-upgrading-wizard-store';
import ITEM_NAVIGATION_STORE from '@/core/item_navigation/store/item-navigation-store';
import EVENT_PLANNER_STORE from '@/identity_domain/vuex/event_planner/event-planner-store';
import EPS_TO_COMPARE_STORE from '@/event_booking_domain/event_planner_compare/vuex/eps-to-compare-store';
import SUPPLIER_STORE from '@/supplier_domain/vuex/supplier-store';
import PERSONAL_CLIENT_STORE from '@/identity_domain/vuex/personal_client/personal-client-store';
import BUSINESS_CLIENT_STORE from '@/identity_domain/vuex/business_client/business-client-store';
import VENUE_MANAGER_STORE from '@/identity_domain/vuex/venue_manager/venue-manager-store';
import ADMIN_STORE from '@/identity_domain/vuex/admin/admin-store';
import EXHIBITOR_STORE from '@/identity_domain/vuex/exhibitor/exhibitor-store';
import EVENT_STORE from '@/identity_domain/vuex/event/event-store';
import EVENT_PLANNER_MARKETPLACE_STORE from '@/event_booking_domain/event_planner_marketplace/vuex/event-planner-marketplace-store';
Vue.use(Vuex);

// eslint-disable-next-line no-unused-vars
const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  modules: {
    authentication: AUTHENTICATION_STORE,
    beverageGroup: BEVERAGE_GROUP_STORE,
    adminLanguages: ADMIN_LANGUAGES_STORE,
    venuesToCompare: VENUES_TO_COMPARE_STORE,
    epsToCompare: EPS_TO_COMPARE_STORE,
    searchPageNavigation: SEARCH_PAGE_NAVIGATION_STORE,
    searchPageCriteria: SEARCH_PAGE_CRITERIA_STORE,
    eventPlannerMarketplace: EVENT_PLANNER_MARKETPLACE_STORE,
    venueUpgradeWizard: VENUE_UPGRADING_WIZARD_STORE,
    itemNavigation: ITEM_NAVIGATION_STORE,
    eventPlanner: EVENT_PLANNER_STORE,
    supplier: SUPPLIER_STORE,
    personalClient: PERSONAL_CLIENT_STORE,
    businessClient: BUSINESS_CLIENT_STORE,
    venueManager: VENUE_MANAGER_STORE,
    exhibitor: EXHIBITOR_STORE,
    admin: ADMIN_STORE,
    event: EVENT_STORE
  }
});
export default store;