export default {
  logout() {
    sessionStorage.clear();
    localStorage.removeItem('refresh-access-token-link');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expired-time');
    localStorage.removeItem('username');
    localStorage.removeItem('organizationId');
  },
  getBasicAuthHeader() {
    return 'Basic cU5GTnBINTZuMEJaM1NsR3c1ZjM1UzRmMWdxejkyZEo6dzc1Mk1nMmFKMzl5NkVhZjE3UjB6emg1VTRZcFY3MDg=';
  }
};