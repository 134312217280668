import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
import EventAuthentication from '@/event_management_domain_new/dashboard/event-list/authentications/event-authentication';
import EventDetailsAuthentication from '@/event_management_domain_new/event/authentications/event-details-authentication';
import EventAuthorization from '@/event_management_domain_new/dashboard/event-list/authorizations/event-authorization';
import EventDetailAuthorization from '@/event_management_domain_new/event/authorizations/event-details-authorization';
import EventReviewAuthorization from '@/public_domain/event_review/authorizations/event-review-authorization';
import PublicEventForCheckInAuthorization from '@/event_management_domain_new/event/public_event_for_check_in/authorizations/public-event-for-check-in-authorization';
import InvitingNewVMForPriceQuoteAuthentication from '@/event_management_domain_new/invite_new_vm_for_price_quote/authentications/inviting-new-vm-for-price-quote-authentication';
import InvitingNewVMForPriceQuoteAuthorization from '@/event_management_domain_new/invite_new_vm_for_price_quote/authorizations/inviting-new-vm-for-price-quote-authorization';
export default [{
  path: '/venue-dashboard',
  name: 'venue-dashboard',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/VenueDashboard.vue'),
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization,
    eventType: 'hosted'
  }
}, {
  path: '/venue-dashboard/tasks',
  component: () => import('@/event_management_domain_new/coopax_tasks/task-in-dashboard/components/DashboardAllTaskContainer.vue'),
  name: 'hosted-event-tasks',
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/event-dashboard',
  name: 'eb-dashboard',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/EventPlannerDashboard.vue'),
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization,
    eventType: 'planned'
  }
}, {
  path: '/event-client-dashboard',
  name: 'event-client-dashboard',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/EventClientDashboard.vue'),
  meta: {
    roles: [OrganizationTypes.PERSONAL_EVENT_CLIENT.value, OrganizationTypes.BUSINESS_EVENT_CLIENT.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization,
    eventType: 'planned'
  }
}, {
  path: '/exhibitor-dashboard',
  name: 'exhibitor-dashboard',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/ExhibitorDashboard.vue'),
  meta: {
    roles: [OrganizationTypes.EXHIBITOR.value, OrganizationTypes.EXHIBITOR.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization,
    eventType: 'planned'
  }
}, {
  path: '/event-dashboard/planned-events',
  name: 'planned-event-list',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/PlannedEvents.vue'),
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value, OrganizationTypes.PERSONAL_EVENT_CLIENT.value, OrganizationTypes.BUSINESS_EVENT_CLIENT.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/venue-dashboard/hosted-events',
  name: 'hosted-event-list',
  component: () => import('@/event_management_domain_new/dashboard/event-list/components/HostedEvents.vue'),
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/event-dashboard/event-planner-requests',
  name: 'ep-quotation-requests',
  component: () => import('@/event_management_domain_new/dashboard/upcoming-event-request-list/components/AllEventPlannerRequests.vue'),
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/event-dashboard/venue-quotation-requests',
  name: 'venue-quotation-requests',
  component: () => import('@/event_management_domain_new/dashboard/upcoming-event-request-list/components/AllVenueQuotationRequests.vue'),
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/event-dashboard/tasks',
  name: 'planned-event-tasks',
  component: () => import('@/event_management_domain_new/coopax_tasks/task-in-dashboard/components/DashboardAllTaskContainer.vue'),
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value, OrganizationTypes.PERSONAL_EVENT_CLIENT.value, OrganizationTypes.BUSINESS_EVENT_CLIENT.value],
    authentication: EventAuthentication,
    authorization: EventAuthorization
  }
}, {
  path: '/public-event-for-check-in/:eventName/:eventCollectionId/event-attendees/:eventAttendeeId',
  name: 'public-event-for-check-in',
  component: () => import('@/event_management_domain_new/event/public_event_for_check_in/PublicEventForCheckInOneAttendee.vue'),
  props: true,
  meta: {
    authentication: EventAuthentication,
    authorization: PublicEventForCheckInAuthorization
  }
}, {
  path: '/public-event-for-check-in-group/:eventName/:eventCollectionId/event-attendees/:eventAttendeeId',
  name: 'public-event-for-check-in-group',
  component: () => import('@/event_management_domain_new/event/public_event_for_check_in/PublicEventForCheckInGroup.vue'),
  props: true,
  meta: {
    authentication: EventAuthentication,
    authorization: PublicEventForCheckInAuthorization
  }
}, {
  path: '/management-event-attendance/:eventCollectionId',
  name: 'management-event-attendance',
  component: () => import('@/event_management_domain_new/event/management_event_attendance/ManagementEventAttendance.vue'),
  props: true
}, {
  path: '/public-event/:eventCollectionSeoId',
  name: 'public-event',
  component: () => import('@/event_management_domain_new/event/public_event/event_public_preview/components/PublicEventPreview.vue'),
  props: true
}, {
  path: '/inviting-new-vm-for-price-quote/:eventCollectionId/private-events/:eventId',
  name: 'inviting-new-vm-for-price-quote',
  component: () => import('@/event_management_domain_new/invite_new_vm_for_price_quote/components/InvitingNewVMForPriceQuote.vue'),
  meta: {
    authentication: InvitingNewVMForPriceQuoteAuthentication,
    authorization: InvitingNewVMForPriceQuoteAuthorization
  },
  props: true
}, {
  path: '/events/:eventCollectionId',
  name: 'event-detail',
  component: () => import('@/event_management_domain_new/event_dashboard/EventDashboardContainer.vue'),
  meta: {
    authentication: EventDetailsAuthentication,
    authorization: EventDetailAuthorization,
    unsupportedMobile: true
  },
  props: true
}, {
  path: '/events-for-sharing-e-p-briefing-form/:eventCollectionId',
  name: 'event-for-sharing-e-p-briefing-form',
  component: () => import('@/event_team_management_domain/event_for_sharing_e_p_briefing_form/components/EventForSharingEPBriefingForm.vue'),
  props: true
}, {
  path: '/sharing-event-ep-briefing-form/:eventCollectionId',
  name: 'sharing-event-ep-briefing-form',
  component: () => import('@/event_management_domain_new/event/event_briefing/components/SharingEventEPBriefingFormPage.vue'),
  props: true
}, {
  path: '/event-review/:eventCollectionId',
  name: 'event-review',
  component: () => import('@/public_domain/event_review/components/EventReviewContainer.vue'),
  props: true,
  meta: {
    authorization: EventReviewAuthorization
  }
}];