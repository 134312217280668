import EventApisEnum from '@/event_management_domain_new/event/constants/event-apis-enum';
import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import store from '@/core/config/vuex';
const EventDetailAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    if (to.params.eventCollectionId === 'offline') {
      if (next && !store.getters.isLoggedIn) {
        next();
      }
    } else {
      await axios.head(EventApisEnum.GET_EVENT_COLLECTION(store.getters.currentOrganization.id, to.params.eventCollectionId)).then(() => {
        if (next) {
          next();
        }
      }).catch(errors => {
        if (errors.response && errors.response.status === httpStatus.FORBIDDEN) {
          GlobalAuthorization.goHomeDueToNoPermissionToAccess();
        }
      });
    }
  },
  async checkPermission(eventCollectionId) {
    await axios.head(EventApisEnum.GET_EVENT_COLLECTION(store.getters.currentOrganization.id, eventCollectionId)).then(() => {}).catch(errors => {
      if (errors.response && errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default EventDetailAuthorization;