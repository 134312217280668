import values from 'lodash/values';
import isFunction from 'lodash/isFunction';
const VenueAvailabilities = {
  ALL_VENUES_BY_HIERARCHY_ORDER: {
    get text() {
      return 'search.venue_availability.all_venues#label';
    },
    value: 'allVenues'
  },
  BOOKABLE_VENUES: {
    get text() {
      return 'search.venue_availability.bookable_venues#label';
    },
    value: 'bookableVenues'
  },
  ADDRESS_VENUES: {
    get text() {
      return 'search.venue_availability.address_venues#label';
    },
    value: 'addressVenues'
  },
  BOOKABLE_AT_OTHER_DATE: {
    get text() {
      return 'search.venue_availability.bookable_at_other_date#label';
    },
    value: 'bookableAtOtherDate'
  },
  getList() {
    return values(this).filter(venueAvailability => !isFunction(venueAvailability));
  }
};
export default VenueAvailabilities;