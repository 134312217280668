import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import EventReviewApis from '@/public_domain/event_review/apis/event-review-apis';
const EventReviewAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    await axios.head(EventReviewApis.GET_EVENT_REVIEW_MANDATORY_INFORMATION(to.params.eventCollectionId), {
      params: to.query
    }).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default EventReviewAuthorization;