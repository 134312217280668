import SEARCH_PAGE_NAVIGATION_ACTIONS from '@/event_booking_domain/search_venues/vuex/search-page-navigation-actions';
const SEARCH_PAGE_NAVIGATION_STORE = {
  state: () => ({
    isFiltersVisible: true,
    isMapVisible: true
  }),
  mutations: {
    updateFiltersVisibility(state, isFiltersVisible) {
      state.isFiltersVisible = isFiltersVisible;
    },
    updateMapVisibility(state, isMapVisible) {
      state.isMapVisible = isMapVisible;
    }
  },
  getters: {
    isFiltersVisible(state) {
      return state.isFiltersVisible;
    },
    isMapVisible(state) {
      return state.isMapVisible;
    }
  },
  actions: {
    updateFiltersVisibility({
      commit
    }, isFiltersVisible) {
      commit(SEARCH_PAGE_NAVIGATION_ACTIONS.updateFiltersVisibility, isFiltersVisible);
    },
    updateMapVisibility({
      commit
    }, isMapVisible) {
      commit(SEARCH_PAGE_NAVIGATION_ACTIONS.updateMapVisibility, isMapVisible);
    }
  }
};
export default SEARCH_PAGE_NAVIGATION_STORE;