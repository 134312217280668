const VenueSettingPageSidebarGroups = {
  INFORMATION: {
    value: 'information'
  },
  SETTINGS: {
    value: 'settings'
  },
  AREAS: {
    value: 'areas'
  },
  EVENT_PAYMENT: {
    value: 'event_payment'
  }
};
export default VenueSettingPageSidebarGroups;