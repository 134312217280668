import EVENT_ACTIONS from '@/identity_domain/vuex/event/event-actions';
var EVENT_STORE = {
  state: function state() {
    return {
      shouldShowEventLimitedFunctionsDialog: false,
      openLicenseRegistrationDialog: false
    };
  },
  getters: {
    shouldShowEventLimitedFunctionsDialog: function shouldShowEventLimitedFunctionsDialog(state) {
      return state.shouldShowEventLimitedFunctionsDialog;
    },
    openLicenseRegistrationDialog: function openLicenseRegistrationDialog(state) {
      return state.openLicenseRegistrationDialog;
    }
  },
  actions: {
    updateShouldShowEventLimitedFunctionsDialog: function updateShouldShowEventLimitedFunctionsDialog(_ref, shouldShowEventLimitedFunctionsDialog) {
      var commit = _ref.commit;
      commit(EVENT_ACTIONS.updateShouldShowEventLimitedFunctionsDialog, shouldShowEventLimitedFunctionsDialog);
    },
    updateOpenLicenseRegistrationDialog: function updateOpenLicenseRegistrationDialog(_ref2, openLicenseRegistrationDialog) {
      var commit = _ref2.commit;
      commit(EVENT_ACTIONS.updateOpenLicenseRegistrationDialog, openLicenseRegistrationDialog);
    }
  },
  mutations: {
    updateShouldShowEventLimitedFunctionsDialog: function updateShouldShowEventLimitedFunctionsDialog(state, shouldShowEventLimitedFunctionsDialog) {
      state.shouldShowEventLimitedFunctionsDialog = shouldShowEventLimitedFunctionsDialog;
    },
    updateOpenLicenseRegistrationDialog: function updateOpenLicenseRegistrationDialog(state, openLicenseRegistrationDialog) {
      state.openLicenseRegistrationDialog = openLicenseRegistrationDialog;
    }
  }
};
export default EVENT_STORE;