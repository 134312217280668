import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/src/styles/main.sass';
import 'vuetify/src/styles/styles.sass';
Vue.use(Vuetify);
var opts = {
  theme: {
    themes: {
      light: {
        primary: '#FF6E40',
        secondary: '#1E88E5',
        secondaryDarken: '#1565C0',
        whiteGrey: '#F5F6F8',
        success: '#53A653',
        information: '#1e88e5',
        warning: '#F7C331',
        green: '#00CC83',
        error: '#F05359',
        whiteBlue: '#F4FAFF',
        grey: '#546E7A',
        mainGrey: '#78909C',
        lightGrey: '#B0BEC5',
        darkBlue: '#37474F',
        white: '#FFFFFF',
        lightBlue: '#1E88E5'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  },
  breakpoint: {
    thresholds: {
      oneGridView: 769.9,
      xs: 600,
      md: 960,
      lg: 1264,
      xl: 1366,
      xxl: 1960
    },
    scrollBarWidth: 24
  }
};
export default new Vuetify(opts);