import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import store from '@/core/config/vuex';
const PublicEventForCheckInAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    if (!store.getters.isLoggedIn) {
      return;
    }
    const link = store.getters.authenticatedUserPossibleActions.find(link => link.rel === 'get_public_event_page_for_check_in_mandatory_information');
    if (!link) {
      GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      return;
    }
    const apiUri = decodeURI(link.href).replace('{event-collection-id}', to.params.eventCollectionId).replace('{event-attendee-id}', to.params.eventAttendeeId);
    await axios.head(apiUri).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default PublicEventForCheckInAuthorization;