import values from 'lodash/values';
export default {
  getFirstBrowserLanguage(languages) {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    let language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      language = nav.languages.find(lang => lang && lang.length > 0);
    }

    // support for other well known properties in browsers
    browserLanguagePropertyKeys.forEach(propertyKey => {
      if (nav[propertyKey] && nav[propertyKey].length > 0) {
        language = nav[propertyKey];
      }
    });
    const isLanguageSupported = values(languages).some(lang => lang === language);
    return isLanguageSupported ? language : 'en-US';
  },
  getPreferredLanguage(languages) {
    const preferredBrowserLanguage = this.getFirstBrowserLanguage(languages);
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return localStorage.getItem('locale') || preferredBrowserLanguage;
    }
    return preferredBrowserLanguage;
  }
};