import VENUE_UPGRADING_WIZARD_ACTIONS from '@/venue_management_domain/venue_upgrade_wizard/vuex/venue-upgrading-wizard-actions';
const VENUE_UPGRADING_WIZARD_STORE = {
  state: () => ({
    venueId: '',
    shouldConnectToUser: false,
    // fetch venue detail after complete login or registration
    shouldFetchVenueDetail: false,
    shouldShowRegisterButtonInLoginModal: true
  }),
  mutations: {
    updateVenueId(state, venueId) {
      state.venueId = venueId;
    },
    updateShouldConnectToUser(state, shouldConnectToUser) {
      state.shouldConnectToUser = shouldConnectToUser;
    },
    updateShouldFetchVenueDetail(state, shouldFetchVenueDetail) {
      state.shouldFetchVenueDetail = shouldFetchVenueDetail;
    },
    updateShouldShowRegisterButtonInLoginModal(state, shouldShowRegisterButtonInLoginModal) {
      state.shouldShowRegisterButtonInLoginModal = shouldShowRegisterButtonInLoginModal;
    }
  },
  getters: {
    venueId(state) {
      return state.venueId;
    },
    shouldConnectToUser(state) {
      return state.shouldConnectToUser;
    },
    shouldFetchVenueDetail(state) {
      return state.shouldFetchVenueDetail;
    },
    shouldShowRegisterButtonInLoginModal(state) {
      return state.shouldShowRegisterButtonInLoginModal;
    }
  },
  actions: {
    updateVenueId({
      commit
    }, venueId) {
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateVenueId, venueId);
    },
    updateShouldConnectToUser({
      commit
    }, shouldConnectToUser) {
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldConnectToUser, shouldConnectToUser);
    },
    updateShouldFetchVenueDetail({
      commit
    }, shouldFetchVenueDetail) {
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldFetchVenueDetail, shouldFetchVenueDetail);
    },
    updateShouldShowRegisterButtonInLoginModal({
      commit
    }, shouldShowRegisterButtonInLoginModal) {
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldShowRegisterButtonInLoginModal, shouldShowRegisterButtonInLoginModal);
    }
  }
};
export default VENUE_UPGRADING_WIZARD_STORE;