import BEVERAGE_GROUP_ACTIONS from '@/catering_domain/venue_beverage_group_type/vuex/beverage-group-actions';
const BEVERAGE_GROUP_STORE = {
  state: () => ({
    beverageTypeHasChanged: false,
    /**
     * Check if any price has changed when mass editing beverage group prices
     */
    priceHasChanged: false
  }),
  mutations: {
    updateBeverageTypeHasChanged(state, hasChanged) {
      state.beverageTypeHasChanged = hasChanged;
    },
    updatePriceHasChanged(state, hasChanged) {
      state.priceHasChanged = hasChanged;
    }
  },
  getters: {
    beverageTypeHasChanged(state) {
      return state.beverageTypeHasChanged;
    },
    isBeverageGroupChanged(state) {
      return state.priceHasChanged || state.beverageTypeHasChanged;
    },
    priceHasChanged(state) {
      return state.priceHasChanged;
    }
  },
  actions: {
    updatePriceHasChanged({
      commit
    }, priceHasChanged) {
      commit(BEVERAGE_GROUP_ACTIONS.updatePriceHasChanged, priceHasChanged);
    },
    updateBeverageTypeHasChanged({
      commit
    }, beverageTypeHasChanged) {
      commit(BEVERAGE_GROUP_ACTIONS.updateBeverageTypeHasChanged, beverageTypeHasChanged);
    },
    updateBeverageGroupHasChanged({
      commit
    }, beverageGroupHasChanged) {
      commit(BEVERAGE_GROUP_ACTIONS.updatePriceHasChanged, beverageGroupHasChanged);
      commit(BEVERAGE_GROUP_ACTIONS.updateBeverageTypeHasChanged, beverageGroupHasChanged);
    }
  }
};
export default BEVERAGE_GROUP_STORE;