import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import store from '@/core/config/vuex';
const InvitingNewVMForPriceQuoteAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    if (!store.getters.isLoggedIn) {
      return;
    }
    if (!store.getters.authenticatedUserPossibleActions.some(link => link.rel === 'get_inviting_new_vm_for_price_quote_mandatory_information')) {
      GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      return;
    }
    const apiUri = decodeURI(store.getters.authenticatedUserPossibleActions.find(link => link.rel === 'get_inviting_new_vm_for_price_quote_mandatory_information').href).replace('{event-collection-id}', to.params.eventCollectionId).replace('{event-id}', to.params.eventId);
    await axios.head(apiUri).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response && errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default InvitingNewVMForPriceQuoteAuthorization;