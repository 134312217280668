import ADMIN_ACTIONS from '@/identity_domain/vuex/admin/admin-actions';
const ADMIN_STORE = {
  state: () => ({
    adminLinks: []
  }),
  getters: {
    adminLinks(state) {
      return state.adminLinks;
    }
  },
  actions: {
    updateAdminLinks({
      commit
    }, adminLinks) {
      commit(ADMIN_ACTIONS.updateAdminLinks, adminLinks);
    }
  },
  mutations: {
    updateAdminLinks(state, adminLinks) {
      state.adminLinks = adminLinks;
    }
  }
};
export default ADMIN_STORE;