import Vue from 'vue';
import VueI18n from 'vue-i18n';
import get from 'lodash/get';
import { ZoneId, LocalDateTime, convert, Instant } from '@js-joda/core';
import VuexStore from '@/core/config/vuex';
import LangUtils from '@/core/utils/language-utils';
Vue.use(VueI18n);
var messages = {};
var DEFAULT_LOCALE = 'en';
export var dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  es: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  nl: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  br: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  'pt-BR': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  },
  de: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    shortMonth: {
      month: 'short',
      day: '2-digit'
    },
    shortMonthUTC: {
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    },
    dateMonthWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: '2-digit',
      hour12: false
    },
    dateMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      month: 'numeric',
      year: 'numeric',
      day: '2-digit',
      hour12: false
    },
    shortMonthWithYear: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateShortMonthYearWithTime: {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    dateLongMonthWithYear: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    dayShortMonthYearWithTime: {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
};
export function getShortCurrency(currency) {
  switch (currency) {
    case 'Euro':
      return 'EUR';
    case 'Real':
      return 'BRL';
    default:
      return 'EUR';
  }
}
function getCurrentLocale() {
  var locale = VuexStore.getters.userSettings && VuexStore.getters.userSettings.languageCode;
  if (locale === 'br') {
    locale = 'pt-BR';
  }
  return locale || 'pt-BR';
}
function getDateFormat() {
  return VuexStore.getters.userSettings && VuexStore.getters.userSettings.dateFormat || 'pt-BR';
}
export function getNumberFormat() {
  return VuexStore.getters.userSettings && VuexStore.getters.userSettings.numberFormat || 'pt-BR';
}

// date and number localization using user's settings
Vue.prototype.$dst = function (date, timezone) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'short';
  if (!date) {
    return '';
  }
  var language = get(dateTimeFormats, getDateFormat());
  var options = get(language, key);
  var zoneId = ZoneId.of(timezone);
  var ldt = LocalDateTime.ofInstant(Instant.ofEpochMilli(date), zoneId);
  var jsDate = convert(ldt).toDate();

  // when display shortMonth: Month + Date, we need to translate them depend on language, instead of using date format
  if (key === 'shortMonth' || key === 'shortMonthWithYear' || key === 'dateShortMonthYearWithTime' || key === 'dateLongMonthWithYear' || key === 'dayShortMonthYearWithTime') {
    return jsDate.toLocaleDateString(getCurrentLocale(), options);
  }
  return jsDate.toLocaleDateString(getDateFormat(), options);
};
var i18n = new VueI18n({
  locale: LangUtils.getPreferredLanguage(messages),
  fallbackLocale: DEFAULT_LOCALE,
  messages: messages,
  dateTimeFormats: dateTimeFormats,
  silentTranslationWarn: true
});

// Format number to a string
Vue.prototype.$ns = function (num) {
  var minDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return i18n.n(num, {
    locale: getNumberFormat(),
    minimumFractionDigits: minDigits,
    maximumFractionDigits: 2
  });
};

// Format double number
Vue.prototype.$nf = function (num) {
  return num && +num.toFixed(2);
};

// Format number as currency
Vue.prototype.$nc = function (num, currency) {
  var minDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  if (num === null || num === undefined) {
    return '';
  }
  return i18n.n(num, {
    locale: getNumberFormat(),
    style: 'currency',
    currency: getShortCurrency(currency),
    minimumFractionDigits: minDigits,
    maximumFractionDigits: 2
  });
};

// Format number as percentage
Vue.prototype.$np = function (num) {
  var number = num;
  if (num > 1) {
    number = num / 100;
  }
  return i18n.n(number, {
    locale: getNumberFormat(),
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

// Format number as percentage without %
// npws: Number Percentage Without Symbol
Vue.prototype.$npws = function (num) {
  var number = num * 100;
  return i18n.n(number, {
    locale: getNumberFormat(),
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};
Vue.prototype.$ds = function (date) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'short';
  if (!date) {
    return '';
  }
  var language = get(dateTimeFormats, getDateFormat());
  var options = get(language, key);
  var userTimezone = VuexStore.getters.userSettings.timezone && VuexStore.getters.userSettings.timezone.text;
  var browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var timezone = userTimezone || browserTimezone;
  var zoneId = ZoneId.of(timezone);
  var ldt = LocalDateTime.ofInstant(Instant.ofEpochMilli(date), zoneId);
  var jsDate = convert(ldt).toDate();

  // when display shortMonth: Month + Date, we need to translate them depend on language, instead of using date format
  if (key === 'shortMonth' || key === 'shortMonthWithYear' || key === 'dateShortMonthYearWithTime') {
    return jsDate.toLocaleDateString(getCurrentLocale(), options);
  }
  return jsDate.toLocaleDateString(getDateFormat(), options);
};
export default i18n;