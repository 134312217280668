import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AuthenticationGetters from '@/identity_domain/authentication/vuex/authentication-getters.js';
import LanguagesConst from '@/crm_domain/languages/constants/languages-const.js';
export default {
  name: 'translator-modal',
  props: {
    visible: Boolean,
    translationKey: String
  },
  data() {
    return {
      translationsGroupedByKeyCollection: [],
      loadingEvent: 'translator-modal'
    };
  },
  watch: {
    translationKey: {
      async handler(newKey) {
        await this.fetchKey(newKey);
      }
    }
  },
  computed: _objectSpread({}, mapGetters({
    userLinks: AuthenticationGetters.authenticatedUserPossibleActions
  })),
  methods: {
    getLangText(languageCode) {
      return this.$t(LanguagesConst.of(languageCode).text);
    },
    closeDialog() {
      this.$emit('update:visible', false);
    },
    async saveTranslation() {
      this.showLoadingOverlay(this.loadingEvent);
      const apiUri = this.userLinks.find(link => link.rel === 'update_one_translation_key').href;
      const newTranslations = this.translationsGroupedByKeyCollection.flatMap(translationsGroupedByKey => translationsGroupedByKey.translations);
      const [resp, err] = await this.$to(this.$http.put(apiUri, newTranslations));
      if (resp && resp.data) {
        this.showSuccessNotification();
        window.location.reload();
      } else if (err) {
        this.showErrorNotification(err.response.data.detail);
      }
      this.hideLoadingOverlay(this.loadingEvent);
    },
    async fetchKey(key) {
      this.showLoadingOverlay(this.loadingEvent);
      const apiLink = this.userLinks.find(link => link.rel === 'find_translation_key');
      if (!apiLink) {
        return;
      }
      const apiUri = decodeURI(apiLink.href);
      const request = {
        key
      };
      const [resp, err] = await this.$to(this.$http.put(apiUri, request));
      if (resp && resp.data) {
        this.translationsGroupedByKeyCollection = resp.data.content;
      } else if (err) {
        this.showErrorNotification(err.response.data.detail);
      }
      this.hideLoadingOverlay(this.loadingEvent);
    }
  }
};