import store from '@/core/config/vuex';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
const EventManagementTeamAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    const hasPermission = store.getters.isEventPlanner;
    if (!hasPermission) {
      GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      return false;
    } else if (next) {
      next();
      return true;
    }
    return false;
  }
};
export default EventManagementTeamAuthorization;