import GlobalAuthentication from '@/identity_domain/login/authentications/global-authentication';
const TaskAuthentication = {
  // eslint-disable-next-line no-unused-vars
  authenticate({
    to,
    from,
    next
  }) {
    GlobalAuthentication.authenticate({
      to,
      from,
      next
    });
  }
};
export default TaskAuthentication;