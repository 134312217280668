import SEARCH_PAGE_CRITERIA_ACTIONS from '@/event_booking_domain/search_venues/vuex/search-page-criteria-actions';
import DefaultSearchCriteria from '@/event_booking_domain/search_venues/constants/default-search-criteria';
const SEARCH_PAGE_CRITERIA_STORE = {
  state: () => ({
    searchCriteria: DefaultSearchCriteria,
    searchPagination: {
      page: 1,
      rowsPerPage: 24
    },
    nextSearchURI: '',
    prevSearchURI: '',
    venueIdListForNavigate: []
  }),
  mutations: {
    updateSearchCriteria(state, searchCriteria) {
      state.searchCriteria = searchCriteria;
    },
    clearSearchCriteria(state) {
      state.searchCriteria = DefaultSearchCriteria;
      state.searchPagination = {
        page: 1,
        rowsPerPage: 24
      };
    },
    updateSearchPagination(state, page) {
      state.searchPagination.page = page;
    },
    updateNextSearchURI(state, nextLink) {
      state.nextSearchURI = nextLink;
    },
    updatePrevSearchURI(state, prevLink) {
      state.prevSearchURI = prevLink;
    },
    updateVenueIdListForNavigate(state, venueIds) {
      state.venueIdListForNavigate = venueIds;
    }
  },
  getters: {
    searchCriteria(state) {
      return state.searchCriteria;
    },
    searchPagination(state) {
      return state.searchPagination;
    },
    nextSearchURI(state) {
      return state.nextSearchURI;
    },
    prevSearchURI(state) {
      return state.prevSearchURI;
    },
    venueIdListForNavigate(state) {
      return state.venueIdListForNavigate;
    }
  },
  actions: {
    updateSearchCriteria({
      commit
    }, searchCriteria) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateSearchCriteria, searchCriteria);
    },
    clearSearchCriteria({
      commit
    }) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.clearSearchCriteria);
    },
    updateSearchPagination({
      commit
    }, page) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateSearchPagination, page);
    },
    updateNextSearchURI({
      commit
    }, nextLink) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateNextSearchURI, nextLink);
    },
    updatePrevSearchURI({
      commit
    }, prevLink) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updatePrevSearchURI, prevLink);
    },
    updateVenueIdListForNavigate({
      commit
    }, venueIds) {
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateVenueIdListForNavigate, venueIds);
    }
  }
};
export default SEARCH_PAGE_CRITERIA_STORE;