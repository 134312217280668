import Vue from 'vue';
const TextInput = () => import('@/core/common/form-inputs/TextInput.vue');
const MultiLanguageTextInput = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageTextInput.vue');
const MultiLanguageSelectInput = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageSelectInput.vue');
const MultiLanguageTextArea = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageTextArea.vue');
const MultiLanguageRichText = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageRichText.vue');
const MultiLanguageWrapper = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageWrapper.vue');
const MultiLanguageSelectMultipleInputShortDescription = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageSelectMultipleInputShortDescription.vue');
const MultiLanguageLabelTextInput = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageLabelTextInput.vue');
const PriceInput = () => import('@/core/common/form-inputs/PriceInput.vue');
const TextAreaInput = () => import('@/core/common/form-inputs/TextAreaInput.vue');
const AnchoredText = () => import('@/core/common/labels/AnchoredText.vue');
const MultiRowAnchoredTextWithTooltip = () => import('@/core/common/labels/MultiRowAnchoredTextWithTooltip.vue');
const FullAnchoredText = () => import('@/core/common/labels/FullAnchoredText.vue');
const SelectInput = () => import('@/core/common/form-inputs/SelectInput.vue');
const AutocompleteInput = () => import('@/core/common/form-inputs/AutocompleteInput.vue');
const PurposeMultiLanguageAutocompleteInput = () => import('@/core/common/form-inputs/multi-language-input/PurposeMultiLanguageAutocompleteInput.vue');
const MultiLanguageAutocompleteInput = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageAutocompleteInput.vue');
const CheckboxInput = () => import('@/core/common/form-inputs/CheckboxInput.vue');
const RadioGroupInput = () => import('@/core/common/form-inputs/RadioGroupInput.vue');
const SwitchInput = () => import('@/core/common/form-inputs/SwitchInput.vue');
const TwoLabelSwitch = () => import('@/core/common/form-inputs/TwoLabelSwitch.vue');
const LanguageSelector = () => import('@/core/common/form-inputs/LanguageSelector.vue');
const VButton = () => import('@/core/common/buttons/VButton.vue');
const SmallButton = () => import('@/core/common/buttons/SmallButton.vue');
const TextButton = () => import('@/core/common/buttons/TextButton.vue');
const ButtonIcon = () => import('@/core/common/buttons/ButtonIcon.vue');
const ButtonIconWithBackground = () => import('@/core/common/buttons/ButtonIconWithBackground.vue');
const ButtonIconToolTip = () => import('@/core/common/buttons/ButtonIconToolTip.vue');
const InListWarningComponent = () => import('@/core/common/InListWarningComponent.vue');
const Modal = () => import('@/core/common/dialogs/Modal.vue');
const ModalNotFullScreenInMobile = () => import('@/core/common/dialogs/ModalNotFullScreenInMobile.vue');
const CardInModal = () => import('@/core/common/dialogs/CardInModal.vue');
const ResponsiveImage = () => import('@/core/common/ResponsiveImage.vue');
const CroppedResponsiveImage = () => import('@/core/common/CroppedResponsiveImage.vue');
const AnchoredHeading = () => import('@/core/common/labels/AnchoredHeading.vue');
const ReadMoreText = () => import('@/core/common/ReadMoreText.vue');
const DataTableComponent = () => import('@/core/common/data-table/DataTableComponent.vue');
const AnchoredCarousel = () => import('@/core/common/carousel/AnchoredCarousel.vue');
const PhotoInList = () => import('@/core/common/photos/PhotoInList.vue');
const IconComponent = () => import('@/core/common/icons/IconComponent.vue');
const IconToolTipComponent = () => import('@/core/common/icons/IconToolTipComponent.vue');
const MultiLanguageIconTooltipComponent = () => import('@/core/common/icons/MultiLanguageIconTooltipComponent.vue');
const NoDataInList = () => import('@/core/common/NoDataInList.vue');
const ButtonInMenu = () => import('@/core/common/buttons/ButtonInMenu.vue');
const AddressLabel = () => import('@/core/common/AddressLabel.vue');
const SelectMultipleInputShortDescription = () => import('@/core/common/form-inputs/SelectMultipleInputShortDescription.vue');
const MultiLanguageLabel = () => import('@/core/common/labels/MultiLanguageLabel.vue');
const TextButtonIconTooltip = () => import('@/core/common/buttons/TextButtonIconTooltip.vue');
const GridDataTableComponent = () => import('@/core/common/data-table/grid-data-table/GridDataTableComponent.vue');
const HorizontalExpandableGridDataTableComponent = () => import('@/core/common/data-table/grid-data-table/HorizontalExpandableGridDataTableComponent.vue');
const GridListComponent = () => import('@/core/common/data-table/grid-list/GridListComponent.vue');
const ServerPagingList = () => import('@/core/common/data-table/grid-list/ServerPagingList.vue');
const ServerPagingTable = () => import('@/core/common/data-table/server-paging-table/ServerPagingTable.vue');
const LocationOptionalImg = () => import('@/core/common/LocationOptionalImg.vue');
const LoadingOverlay = () => import('@/core/components/LoadingOverlay.vue');
const DateInput = () => import('@/core/common/DateInput.vue');
const ButtonTooltip = () => import('@/core/common/buttons/ButtonTooltip.vue');
const ButtonIconTextTooltip = () => import('@/core/common/buttons/ButtonIconTextTooltip.vue');
const TimePicker = () => import('@/core/common/form-inputs/TimePicker.vue');
const TwoTimesPicker = () => import('@/core/common/form-inputs/TwoTimesPicker.vue');
const TwoDatesTimesPicker = () => import('@/core/common/form-inputs/TwoDatesTimesPicker.vue');
const DateTimePicker = () => import('@/core/common/form-inputs/DateTimePicker.vue');
const PhotoUploader = () => import('@/core/common/photos/uploader/admin/PhotoUploader.vue');
const VGridRow = () => import('@/core/common/VGridRow.vue');
const SelectableLanguageList = () => import('@/core/common/form-inputs/SelectableLanguageList.vue');
const AutocompleteTextInput = () => import('@/core/common/form-inputs/AutocompleteTextInput.vue');
const AutocompleteSelectOrAddNewInput = () => import('@/core/common/form-inputs/AutocompleteSelectOrAddNewInput.vue');
const OrganizationAvatar = () => import('@/core/common/OrganizationAvatar.vue');
const EditingItemNavigationTemplate = () => import('@/core/item_navigation/components/EditingItemNavigationTemplate.vue');
const MultiLanguageCheckboxInput = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageCheckboxInput.vue');
const MultiLanguageHeading = () => import('@/core/common/form-inputs/multi-language-input/MultiLanguageHeading.vue');
const FormCard = () => import('@/core/common/FormCard.vue');
const RatingStar = () => import('@/core/common/RatingStar.vue');
const MoscowButton = () => import('@/core/common/buttons/moscow_button/MoscowButton.vue');
const SkeletonLoader = () => import('@/core/components/skeleton-loader/SkeletonLoader.vue');
Vue.component('moscow-button', MoscowButton);
Vue.component('rating-star', RatingStar);
Vue.component('multi-language-select-multiple-input-short-description', MultiLanguageSelectMultipleInputShortDescription);
Vue.component('organization-avatar', OrganizationAvatar);
Vue.component('selectable-language-list', SelectableLanguageList);
Vue.component('multi-language-select-input', MultiLanguageSelectInput);
Vue.component('photo-uploader', PhotoUploader);
Vue.component('two-dates-times-picker', TwoDatesTimesPicker);
Vue.component('date-time-picker', DateTimePicker);
Vue.component('two-times-picker', TwoTimesPicker);
Vue.component('date-input', DateInput);
Vue.component('time-input', TimePicker);
Vue.component('location-optional-img', LocationOptionalImg);
Vue.component('loading-overlay', LoadingOverlay);
Vue.component('text-input', TextInput);
Vue.component('multi-language-text-input', MultiLanguageTextInput);
Vue.component('multi-language-wrapper', MultiLanguageWrapper);
Vue.component('select-input', SelectInput);
Vue.component('autocomplete-input', AutocompleteInput);
Vue.component('purpose-multi-language-autocomplete-input', PurposeMultiLanguageAutocompleteInput);
Vue.component('multi-language-autocomplete-input', MultiLanguageAutocompleteInput);
Vue.component('multi-language-text-area', MultiLanguageTextArea);
Vue.component('multi-language-rich-text', MultiLanguageRichText);
Vue.component('checkbox-input', CheckboxInput);
Vue.component('radio-group-input', RadioGroupInput);
Vue.component('switch-input', SwitchInput);
Vue.component('two-label-switch', TwoLabelSwitch);
Vue.component('v-button', VButton);
Vue.component('small-button', SmallButton);
Vue.component('language-selector', LanguageSelector);
Vue.component('text-button', TextButton);
Vue.component('button-icon', ButtonIcon);
Vue.component('button-icon-with-background', ButtonIconWithBackground);
Vue.component('button-in-menu', ButtonInMenu);
Vue.component('button-icon-tooltip', ButtonIconToolTip);
Vue.component('v-modal', Modal);
Vue.component('v-modal-not-full-screen-in-mobile', ModalNotFullScreenInMobile);
Vue.component('card-in-modal', CardInModal);
Vue.component('anchored-heading', AnchoredHeading);
Vue.component('anchored-text', AnchoredText);
Vue.component('multi-row-anchored-text', MultiRowAnchoredTextWithTooltip);
Vue.component('full-anchored-text', FullAnchoredText);
Vue.component('responsive-image', ResponsiveImage);
Vue.component('cropped-responsive-image', CroppedResponsiveImage);
Vue.component('read-more-text', ReadMoreText);
Vue.component('data-table-component', DataTableComponent);
Vue.component('anchored-carousel', AnchoredCarousel);
Vue.component('icon-component', IconComponent);
Vue.component('no-data-in-list', NoDataInList);
Vue.component('photo-in-list', PhotoInList);
Vue.component('text-area-input', TextAreaInput);
Vue.component('select-multiple-input-short-description', SelectMultipleInputShortDescription);
Vue.component('price-input', PriceInput);
Vue.component('address-label', AddressLabel);
Vue.component('multi-language-label', MultiLanguageLabel);
Vue.component('text-button-icon-tooltip', TextButtonIconTooltip);
Vue.component('grid-data-table-component', GridDataTableComponent);
Vue.component('horizontal-expandable-grid-data-table-component', HorizontalExpandableGridDataTableComponent);
Vue.component('grid-list-component', GridListComponent);
Vue.component('server-paging-table', ServerPagingTable);
Vue.component('server-paging-list', ServerPagingList);
Vue.component('button-tooltip', ButtonTooltip);
Vue.component('button-icon-text-tooltip', ButtonIconTextTooltip);
Vue.component('v-grid-row', VGridRow);
Vue.component('icon-tooltip', IconToolTipComponent);
Vue.component('multi-language-icon-tooltip', MultiLanguageIconTooltipComponent);
Vue.component('in-list-warning', InListWarningComponent);
Vue.component('auto-complete-text-input', AutocompleteTextInput);
Vue.component('editing-item-navigation-template', EditingItemNavigationTemplate);
Vue.component('autocomplete-select-or-add-new-input', AutocompleteSelectOrAddNewInput);
Vue.component('multi-language-label-text-input', MultiLanguageLabelTextInput);
Vue.component('multi-language-checkbox-input', MultiLanguageCheckboxInput);
Vue.component('multi-language-heading', MultiLanguageHeading);
Vue.component('form-card', FormCard);
Vue.component('skeleton-loader', SkeletonLoader);