import EVENT_PLANNER_ACTIONS from '@/identity_domain/vuex/event_planner/event-planner-actions';
const EVENT_PLANNER_STORE = {
  state: () => ({
    eventPlannerOrganizationLinks: [],
    eventPlannerId: null,
    isEventPlannerOnline: false,
    canMakeEventPlannerOnline: false,
    shouldGetEventPlannerInfo: false
  }),
  getters: {
    eventPlannerId(state) {
      return state.eventPlannerId;
    },
    isEventPlannerOnline(state) {
      return state.isEventPlannerOnline;
    },
    canMakeEventPlannerOnline(state) {
      return state.canMakeEventPlannerOnline;
    },
    shouldGetEventPlannerInfo(state) {
      return state.shouldGetEventPlannerInfo;
    },
    eventPlannerOrganizationLinks(state) {
      return state.eventPlannerOrganizationLinks;
    }
  },
  actions: {
    updateEventPlannerId({
      commit
    }, eventPlannerId) {
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerId, eventPlannerId);
    },
    updateEventPlannerStatus({
      commit
    }, isOnline) {
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerStatus, isOnline);
    },
    updateCanMakeEventPlannerOnline({
      commit
    }, canMakeEventPlannerOnline) {
      commit(EVENT_PLANNER_ACTIONS.updateCanMakeEventPlannerOnline, canMakeEventPlannerOnline);
    },
    updateShouldGetEventPlannerInfo({
      commit
    }, shouldGetEventPlannerInfo) {
      commit(EVENT_PLANNER_ACTIONS.updateShouldGetEventPlannerInfo, shouldGetEventPlannerInfo);
    },
    updateEventPlannerOrganizationLinks({
      commit
    }, eventPlannerOrganizationLinks) {
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerOrganizationLinks, eventPlannerOrganizationLinks);
    }
  },
  mutations: {
    updateEventPlannerId(state, eventPlannerId) {
      state.eventPlannerId = eventPlannerId;
    },
    updateEventPlannerStatus(state, isOnline) {
      state.isEventPlannerOnline = isOnline;
    },
    updateCanMakeEventPlannerOnline(state, canMakeEventPlannerOnline) {
      state.canMakeEventPlannerOnline = canMakeEventPlannerOnline;
    },
    updateShouldGetEventPlannerInfo(state, shouldGetEventPlannerInfo) {
      state.shouldGetEventPlannerInfo = shouldGetEventPlannerInfo;
    },
    updateEventPlannerOrganizationLinks(state, eventPlannerOrganizationLinks) {
      state.eventPlannerOrganizationLinks = eventPlannerOrganizationLinks;
    }
  }
};
export default EVENT_PLANNER_STORE;