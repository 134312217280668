import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TranslatorModal from '@/i18n_translation/ui_translation/TranslatorModal.vue';
import throttle from 'lodash/throttle';
import { mapGetters } from 'vuex';
import AUTHENTICATION_GETTERS from '@/identity_domain/authentication/vuex/authentication-getters';
import bus from '@/core/event-bus';
import ResponsiveMixin from './core/mixins/responsive';
import LanguagesConstant from './crm_domain/languages/constants/languages-const';
const LoginAfterTokenExpiredModal = () => import('@/identity_domain/authentication/components/LoginAfterTokenExpiredModal.vue');
const ExtendSessionModal = () => import('@/identity_domain/authentication/components/ExtendSessionModal.vue');
const ReleaseNoteModal = () => import('@/public_domain/user_release_note_visibility/components/ReleaseNoteModal.vue');
const SplashScreen = () => import('@/core/components/SplashScreen.vue');
const AppHeader = () => import('@/core/components/header/Header.vue');
const AppFooter = () => import('@/core/components/footer/Footer.vue');
const ToastMessage = () => import('@/core/common/ToastMessage.vue');
const AuthenticationComponent = () => import('@/identity_domain/authentication/components/AuthenticationComponent.vue');
export default {
  mixins: [ResponsiveMixin],
  beforeMount() {
    const language = this.locale === LanguagesConstant.NL.value ? this.locale : LanguagesConstant.EN.value;
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=${language}`;
    script.async = true;
    script.defer = true;
    document.querySelector('body').appendChild(script);
  },
  components: {
    TranslatorModal,
    LoginAfterTokenExpiredModal,
    ExtendSessionModal,
    ReleaseNoteModal,
    AppHeader,
    AppFooter,
    SplashScreen,
    AppNotification: ToastMessage,
    AuthenticationComponent
  },
  data() {
    return {
      notification: {
        visible: false,
        message: '',
        type: 'success'
      },
      translatingKey: '',
      translatorModalVisible: false,
      extendSessionModalVisible: false,
      loginAfterTokenExpiredModalVisible: false,
      locale: this.$i18n.locale.substring(0, 2)
    };
  },
  async created() {
    // The 'scroll' event is disabled when we use overflow-auto
    // we need to use 'wheel' event
    window.addEventListener('wheel', throttle(this.onScrolled, 300));
    window.addEventListener('touchmove', throttle(this.onTouchmove, 300));
    setTimeout(() => {
      document.dispatchEvent(new Event('app-rendered'));
    }, 5000);
  },
  destroyed() {
    window.removeEventListener('wheel', throttle(this.onScrolled, 300));
    window.removeEventListener('touchmove', throttle(this.onTouchmove, 300));
  },
  async mounted() {
    bus.$on('app-notification', (message, type) => {
      this.notification.visible = false;
      setTimeout(() => {
        this.notification.message = this.$t(message);
        this.notification.type = type;
        this.notification.visible = true;
      }, 250);
    });
    bus.$on('close-app-notification', () => {
      this.notification.visible = false;
    });
    bus.$on('login#token-is-about-to-expired', () => {
      this.openExtendSessionModal();
    });
    bus.$on('axios#token#expired', () => {
      this.openLoginAfterTokenExpiredModal();
    });
    bus.$on('i18n#translate#command', translationKey => {
      this.translatorModalVisible = true;
      this.translatingKey = translationKey;
    });
  },
  computed: _objectSpread({}, mapGetters({
    shouldShowReleaseNoteNotificationModal: AUTHENTICATION_GETTERS.shouldShowReleaseNoteNotification
  })),
  methods: {
    onScrolled(scrollEvent) {
      bus.$emit('app-scrolled', scrollEvent);
    },
    onTouchmove(touchMoveEvent) {
      bus.$emit('app-touch-move', touchMoveEvent);
    },
    openExtendSessionModal() {
      this.extendSessionModalVisible = true;
    },
    openLoginAfterTokenExpiredModal() {
      this.loginAfterTokenExpiredModalVisible = true;
    }
  }
};