import EventManagementTeamAuthentication from '@/event_team_management_domain/authentications/event-management-team-authentication';
import EventManagementTeamAuthorization from '@/event_team_management_domain/authorizations/event-management-team-authorization';
import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
export default [{
  path: '/event-planner-characteristics',
  name: 'event-planner-characteristics',
  component: () => import('@/event_team_management_domain/event_planner_characteristics/components/EventPlannerCharacteristicsWrapper.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-main-services',
  name: 'event-planner-main-services',
  component: () => import('@/event_team_management_domain/event_planner_main_services/components/EventPlannerMainServicesWrapper.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-main-services/:serviceId',
  name: 'event-planner-main-service',
  component: () => import('@/event_team_management_domain/event_planner_main_services/components/EventPlannerMainServiceWrapper.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-briefing-forms/:briefingFormId',
  name: 'event-planner-briefing-form',
  component: () => import('@/event_team_management_domain/event_planner_briefing_form/components/EventPlannerBriefingForm.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-briefing-forms',
  name: 'event-planner-briefing-forms',
  component: () => import('@/event_team_management_domain/event_planner_briefing_form/components/EventPlannerBriefingForms.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-preferred-venues',
  name: 'event-planner-preferred-venues',
  component: () => import('@/event_team_management_domain/event_planner_preferred_venues/components/EventPlannerPreferredVenuesWrapper.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-preferred-venues/add-new',
  name: 'add-preferred-venues',
  component: () => import('@/event_team_management_domain/event_planner_preferred_venues/components/AddNewPreferredVenuePageWrapper.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-details',
  name: 'event-planner-details',
  component: () => import('@/event_team_management_domain/event_planner_details/components/EventPlannerDetails.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/public-event-planner/:eventPlannerSeoId',
  name: 'public-event-planner',
  component: () => import('@/event_team_management_domain/event_planner_public_pages/components/PublicEventPlannerPage.vue'),
  props: true
}, {
  path: '/event-planner-templates',
  name: 'event-planner-templates',
  component: () => import('@/event_booking_domain/event_planner_templates/components/EventPlannerEventTemplates.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-templates/:eventTemplateId',
  name: 'event-planner-template',
  component: () => import('@/event_booking_domain/event_planner_templates/components/EventPlannerEventTemplateContainer.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/suppliers',
  name: 'event-planner-suppliers',
  component: () => import('@/event_team_management_domain/event_planner_supplier/components/EventPlannerSuppliersContainer.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/suppliers/:supplierId',
  name: 'supplier',
  component: () => import('@/event_team_management_domain/event_planner_supplier/components/Supplier.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-widget',
  name: 'event-planner-widget-container',
  component: () => import('@/event_booking_domain/event_planner_widget/components/EventPlannerWidgetContainer.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  },
  children: [{
    path: '',
    name: 'event-planner-widget',
    component: () => import('@/event_booking_domain/event_planner_widget/components/EventPlannerWidget.vue'),
    props: true,
    meta: {
      roles: [OrganizationTypes.EVENT_PLANNER.value],
      authentication: EventManagementTeamAuthentication,
      authorization: EventManagementTeamAuthorization
    }
  }, {
    path: '/organization-widgets',
    name: 'event-planner-organization-widgets',
    component: () => import('@/event_booking_domain/organization_widget/components/event_planner_organization_widget/EventPlannerOrganizationWidgets.vue'),
    props: true,
    meta: {
      roles: [OrganizationTypes.EVENT_PLANNER.value],
      authentication: EventManagementTeamAuthentication,
      authorization: EventManagementTeamAuthorization
    }
  }, {
    path: '/organization-widget/:organizationWidgetSettingId',
    name: 'event-planner-organization-widget',
    component: () => import('@/event_booking_domain/organization_widget/components/event_planner_organization_widget/EventPlannerOrganizationWidget.vue'),
    props: true,
    meta: {
      roles: [OrganizationTypes.EVENT_PLANNER.value],
      authentication: EventManagementTeamAuthentication,
      authorization: EventManagementTeamAuthorization
    }
  }]
}, {
  path: '/event-planner-finance',
  name: 'event-planner-finance',
  component: () => import('@/financial_domain/event_planner_payment_methods/components/EventPlannerFinance.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-image-repository',
  name: 'event-planner-image-repository',
  component: () => import('@/core/common/photos/uploader/event-planner/EventPlannerManageImageRepository.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-policy',
  name: 'event-planner-policy',
  component: () => import('@/event_team_management_domain/event_planner_policies/components/EventPlannerPolicies.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-item-categories',
  name: 'event-planner-item-categories',
  component: () => import('@/catering_domain/organization_item_categories/components/OrganizationItemCategories.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-item-categories/:itemCategoryId',
  name: 'event-planner-item-category',
  component: () => import('@/catering_domain/organization_item_categories/components/OrganizationItemCategory.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}, {
  path: '/event-planner-compare',
  name: 'event-planner-compare',
  component: () => import('@/event_booking_domain/event_planner_compare/components/EPComparePage.vue'),
  props: true
}, {
  path: '/event-planner-paid-invoices',
  name: 'event-planner-paid-invoices',
  component: () => import('@/financial_domain/customer_paid_invoices/components/EventPlannerPaidInvoices.vue'),
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value],
    authentication: EventManagementTeamAuthentication,
    authorization: EventManagementTeamAuthorization
  }
}];