import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import values from 'lodash/values';
import isFunction from 'lodash/isFunction';
var PurposeCategory = {
  BUSINESS: {
    value: 'business',
    get text() {
      return 'admin.purpose.category.business#text';
    }
  },
  OTHER: {
    value: 'other',
    get text() {
      return 'admin.purpose.category.other#text';
    }
  },
  getList: function getList(i18n) {
    return values(this).filter(function (category) {
      return !isFunction(category);
    }).map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        get text() {
          return i18n.t(item.text);
        }
      });
    });
  },
  findTextByValue: function findTextByValue(value) {
    if (!value) {
      return '';
    }
    var item = values(this).find(function (category) {
      return category.value === value;
    });
    return item ? item.text : '';
  },
  findTextByValueList: function findTextByValueList(i18n, categoryValue) {
    var _this = this;
    var categories = categoryValue.split(',');
    return categories.map(function (category) {
      return i18n.t(_this.findTextByValue(category));
    }).join(', ');
  }
};
export default PurposeCategory;