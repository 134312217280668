const AUTHENTICATION_GETTERS = {
  showLoginModal: 'showLoginModal',
  showLoginToOrganizationModal: 'showLoginToOrganizationModal',
  showRegisterModal: 'showRegisterModal',
  isAdmin: 'isAdmin',
  isSuperAdminRole: 'isSuperAdminRole',
  isAdminRole: 'isAdminRole',
  isHelpdeskRole: 'isHelpdeskRole',
  isLoggedIn: 'isLoggedIn',
  isLoggedInToOrganization: 'isLoggedInToOrganization',
  isVenueManager: 'isVenueManager',
  isEventPlanner: 'isEventPlanner',
  isSupplier: 'isSupplier',
  isExhibitor: 'isExhibitor',
  isClient: 'isClient',
  offlineEvent: 'offlineEvent',
  epRequestQuoteWizardVisible: 'epRequestQuoteWizardVisible',
  showRequestVenueFunctionalityModal: 'showRequestVenueFunctionalityModal',
  currentUser: 'currentUser',
  currentOrganizationId: 'currentOrganizationId',
  isPersonalAccount: 'isPersonalAccount',
  isBusinessAccount: 'isBusinessAccount',
  numberFormat: 'numberFormat',
  userLanguage: 'userLanguage',
  isTranslatorMode: 'isTranslatorMode',
  isTranslator: 'isTranslator',
  showRegisterOrganizationFromUserProfileModal: 'showRegisterOrganizationFromUserProfileModal',
  shouldFetchOrganizationsInUserProfile: 'shouldFetchOrganizationsInUserProfile',
  userSettings: 'userSettings',
  shouldShowPriceInclVat: 'shouldShowPriceInclVat',
  currentOrganization: 'currentOrganization',
  nextRoute: 'nextRoute',
  userTimezone: 'userTimezone',
  countryCodeFromUserBrowser: 'countryCodeFromUserBrowser',
  shouldShowReleaseNoteNotification: 'shouldShowReleaseNoteNotification',
  plannedEventNumber: 'plannedEventNumber',
  authenticatedUserPossibleActions: 'authenticatedUserPossibleActions'
};
export default AUTHENTICATION_GETTERS;