import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import messageRoutes from '@/communication_domain/organization_message/routes/message-routes';
import VuexStore from '@/core/config/vuex';
import bus from '@/core/event-bus.js';
import adminRoutes from '@/core/routes/admin-routes';
import locationRoutes from '@/core/routes/location-routes';
import PagesDontRequireLogin from '@/core/routes/pages-dont-require-login';
import unsubscribeRoutes from '@/core/routes/unsubscribe-routes';
import ExhibitorActions from '@/identity_domain/vuex/exhibitor/exhibitor-actions.js';
import supplierRoutes from '@/supplier_domain/routes/supplier-routes';
import AuthUtils from '@/core/utils/auth';
import organizationProfileRoutes from '@/crm_domain/organization/routes/organization-profile-routes';
import userProfileRoutes from '@/crm_domain/users/routes/user-profile-routes';
import eventRoutes from '@/event_management_domain_new/routes/event-routes';
import eventTeamRoutes from '@/event_team_management_domain/routes/event-team-routes';
import taskRoutes from '@/event_management_domain_new/coopax_tasks/task-routes';
import VenueInvoiceAuthentication from '@/financial_domain/venue_invoices/authentications/venue-invoice-authentication';
import VenueInvoiceAuthorization from '@/financial_domain/venue_invoices/authorization/venue-invoices-authorization';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
import CalendarAuthentication from '@/property_management_domain/calendar/authentications/calendar-authentication';
import CalendarAuthorization from '@/property_management_domain/calendar/authorizations/calendar-authorization';
import VenueCustomerGroupsAuthorization from '@/financial_domain/venue_customer_groups/authorization/venue-customer-groups-authorization';
import VenueCustomerGroupsAuthentication from '@/financial_domain/venue_customer_groups/authentications/venue-customer-groups-authentication';
import SUPPLIER_ACTIONS from '@/supplier_domain/vuex/supplier-actions';
import PERSONAL_CLIENT_ACTIONS from '@/identity_domain/vuex/personal_client/personal-client-actions';
import BUSINESS_CLIENT_ACTIONS from '@/identity_domain/vuex/business_client/business-client-actions';
import EVENT_PLANNER_ACTIONS from '@/identity_domain/vuex/event_planner/event-planner-actions';
import VENUE_MANAGER_ACTIONS from '@/identity_domain/vuex/venue_manager/venue-manager-actions';
import ADMIN_ACTIONS from '@/identity_domain/vuex/admin/admin-actions';
import axios from 'axios';
import has from 'lodash/has';
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* eslint-disable no-console */
const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'home',
    component: () => import('@/event_booking_domain/home_page/components/HomePage.vue'),
    alias: ['/reset-password'],
    props: true
  }, {
    path: '/search',
    name: 'search',
    component: () => import('@/event_booking_domain/search_venues/components/SearchPage.vue')
  }, {
    path: '/event-planner-marketplace',
    name: 'event-planner-marketplace',
    component: () => import('@/event_booking_domain/event_planner_marketplace/components/EventPlannerMarketplace.vue')
  }, {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/property_management_domain/calendar/components/VenueAreaCalendar.vue'),
    meta: {
      roles: [OrganizationTypes.VENUE_MANAGER.value],
      authentication: CalendarAuthentication,
      authorization: CalendarAuthorization,
      unsupportedMobile: true
    }
  }, {
    path: '/venue-paid-invoices',
    name: 'venue-manager-paid-invoices',
    component: () => import('@/financial_domain/customer_paid_invoices/components/VenueManagerPaidInvoices.vue'),
    meta: {
      roles: [OrganizationTypes.VENUE_MANAGER.value],
      authentication: CalendarAuthentication,
      authorization: CalendarAuthorization,
      unsupportedMobile: true
    }
  }, {
    path: '/venue-invoices',
    name: 'venue-invoices-container',
    component: () => import('@/financial_domain/venue_invoices/components/VenueInvoicesContainer.vue'),
    meta: {
      roles: [OrganizationTypes.VENUE_MANAGER.value],
      authorization: VenueInvoiceAuthorization,
      authentication: VenueInvoiceAuthentication
    },
    children: [{
      path: 'normal-invoices',
      name: 'venue-invoices',
      component: () => import('@/financial_domain/venue_invoices/components/VenueInvoicesTable.vue'),
      meta: {
        roles: [OrganizationTypes.VENUE_MANAGER.value],
        authorization: VenueInvoiceAuthorization,
        authentication: VenueInvoiceAuthentication
      }
    }, {
      path: 'generated-period-invoices',
      name: 'generated-period-invoices',
      component: () => import('@/financial_domain/venue_invoices/components/VenueGeneratedPeriodInvoicesTable.vue'),
      meta: {
        roles: [OrganizationTypes.VENUE_MANAGER.value],
        authorization: VenueInvoiceAuthorization,
        authentication: VenueInvoiceAuthentication
      }
    }]
  }, {
    path: '/venue-customer-groups',
    name: 'venue-customer-groups',
    component: () => import('@/financial_domain/venue_customer_groups/components/VenueCustomerGroups.vue'),
    meta: {
      roles: [OrganizationTypes.VENUE_MANAGER.value],
      authorization: VenueCustomerGroupsAuthorization,
      authentication: VenueCustomerGroupsAuthentication
    }
  }, {
    path: '/venue-customer-groups/:venueCustomerGroupId',
    name: 'venue-customer-group',
    component: () => import('@/financial_domain/venue_customer_groups/components/VenueCustomerGroup.vue'),
    meta: {
      roles: [OrganizationTypes.VENUE_MANAGER.value],
      authorization: VenueCustomerGroupsAuthorization,
      authentication: VenueCustomerGroupsAuthentication
    },
    props: true
  }, {
    path: '/venue-manager-organization-widgets',
    name: 'venue-manager-organization-widgets',
    component: () => import('@/event_booking_domain/organization_widget/components/venue_organization_widget/VenueManagerOrganizationWidgets.vue'),
    props: true
  }, {
    path: '/venue-manager-organization-widget/:organizationWidgetSettingId',
    name: 'venue-manager-organization-widget',
    component: () => import('@/event_booking_domain/organization_widget/components/venue_organization_widget/VenueManagerOrganizationWidget.vue'),
    props: true
  }, {
    path: '/venue-manager-image-repository',
    name: 'venue-manager-image-repository',
    component: () => import('@/core/common/photos/uploader/venue/VenueManagerManageImageRepository.vue'),
    props: true
  }, {
    path: '/venue-manager-policies',
    name: 'venue-manager-policies',
    component: () => import('@/venue_management_domain/venue_organization_policies/components/VenueOrganizationPolicies.vue'),
    props: true
  }, {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import('@/compliance_domain/cookie_policy/CookiePolicy.vue')
  }, {
    path: '/unsupported-mobile',
    name: 'unsupported-mobile',
    component: () => import('@/venue_management_domain/venue_information/components/unsupported-mobile-page/UnsupportedMobilePage.vue')
  }, {
    path: '/release-notes',
    name: 'public-release-notes',
    component: () => import('@/public_domain/user_release_note_visibility/components/PublicReleaseNote.vue')
  }, {
    path: '/api-documents/get-venues-info-by-admin-franchise-id',
    name: 'get-venues-info-by-admin-franchise-id',
    component: () => import('@/public_domain/api_documents/components/GetVenuesInfoByAdminFranchiseIdApiDocument.vue')
  }, {
    path: '/api-documents/get-venues-info-by-venue-ids',
    name: 'get-venues-info-by-venue-ids',
    component: () => import('@/public_domain/api_documents/components/GetVenuesInfoByVenueIdsApiDocument.vue')
  }, {
    path: '/api-documents/get-venue-ids-by-venue-names',
    name: 'get-venue-ids-by-venue-names',
    component: () => import('@/public_domain/api_documents/components/GetVenueIdsByVenueNamesApiDocument.vue')
  }, {
    path: '/api-documents/get-admin-franchises-by-names',
    name: 'get-admin-franchises-by-names',
    component: () => import('@/public_domain/api_documents/components/GetAdminFranchisesByNamesApiDocument.vue')
  }, {
    path: '/documents/venue-widgets-and-links',
    name: 'venue-widget-and-links',
    component: () => import('@/public_domain/documents/components/VenueWidgetDocumentPage.vue')
  }, {
    path: '/event-collections/:eventCollectionId/invoices/:invoiceType/:invoiceId/download',
    name: 'download-invoice-pdf',
    props: true,
    component: () => import('@/financial_domain/customer_paid_invoices/components/DownloadInvoicePdfPage.vue')
  }, ...eventRoutes, ...eventTeamRoutes, ...locationRoutes, ...userProfileRoutes, ...organizationProfileRoutes, ...supplierRoutes, ...taskRoutes, ...adminRoutes, ...unsubscribeRoutes, ...messageRoutes],
  scrollBehavior() {
    // This is a temporary fixed, since we use a fixed header,
    // the vuejs default capability doesn't know which wrapper should be used for wrap
    // so we need to tell them which is the main wrapper
    document.querySelector('.v-application--wrap').scrollTop = 0;
  }
});
function navigateAfterRegister(to, next) {
  const token = to.path.split('/')[2];
  if (token) {
    AuthUtils.logout();
    VuexStore.commit(AUTHENTICATION_ACTIONS.logOut);
    next({
      path: '/',
      query: {
        token,
        organizationRole: to.query.organizationRole,
        registerFromEventInvitation: !!to.path.startsWith('/registerFromEventInvitation/')
      }
    });
  } else {
    next('/');
  }
}
async function activateUser(code) {
  return axios.post('/v1/users/activation', {
    activationCode: code
  }, {
    headers: {
      Authorization: AuthUtils.getBasicAuthHeader()
    }
  });
}
async function navigateAfterActivateUser(to, next) {
  AuthUtils.logout();
  VuexStore.commit(AUTHENTICATION_ACTIONS.logOut);
  const {
    code
  } = to.query;
  try {
    await activateUser(code);
    next({
      path: '/',
      query: {
        activate: 'success',
        activationCode: code
      }
    });
  } catch (error) {
    bus.$emit('app-notification', error.response.data.detail, 'error');
    next({
      path: '/'
    });
  }
}
function navigateToUnsupportedMobile(from, next, to) {
  const unsupportedMobilePageName = 'unsupported-mobile';
  const oneGridViewPort = 770;
  const isOneGridPage = window.innerWidth < oneGridViewPort;
  if (from.name !== unsupportedMobilePageName && isOneGridPage) {
    next({
      name: unsupportedMobilePageName,
      query: _objectSpread(_objectSpread({}, to.query), {}, {
        toPage: to.fullPath,
        fromPage: from.fullPath,
        toPageName: to.name
      })
    });
  }
}
async function authorizeUserRoles(next, to, from) {
  if (VuexStore.getters.isAdmin) {
    next();
  } else {
    await to.meta.authorization.authorize({
      to,
      from,
      next
    });
  }
}
function shouldAuthenticateBeforeOpenPage(to) {
  return has(to.meta, 'authentication');
}
function shouldAuthorizeBeforeOpenPage(to) {
  return has(to.meta, 'authorization');
}
function setOrganizationInformationToStore(loginResponse) {
  if (loginResponse.links.some(link => link.rel === 'supplier_logged_in')) {
    VuexStore.commit(SUPPLIER_ACTIONS.updateSupplierId, loginResponse.loggedInSupplierInfo.supplierId);
  }
}
function isLoggedIn() {
  return localStorage.getItem('refresh-access-token-link');
}
async function refreshLoggedInInformationFromAccessToken() {
  if (isLoggedIn()) {
    const apiUri = localStorage.getItem('refresh-access-token-link');
    const resp = await axios.post(apiUri, {});
    if (resp && resp.data) {
      VuexStore.commit(AUTHENTICATION_ACTIONS.updateCurrentOrganization, resp.data.loggedInOrganization);
      VuexStore.commit(AUTHENTICATION_ACTIONS.updateUser, resp.data.loggedInUserProfile);
      VuexStore.commit(AUTHENTICATION_ACTIONS.updateUserSettings, resp.data.loggedInUserSetting);
      VuexStore.commit(SUPPLIER_ACTIONS.updateLoggedInSupplier, resp.data.loggedInSupplierInfo);
      VuexStore.commit(AUTHENTICATION_ACTIONS.updateAuthenticatedUserPossibleActions, resp.data.links);
      setOrganizationInformationToStore(resp.data);
      if (resp.data.links.some(link => link.rel === 'supplier_logged_in')) {
        VuexStore.commit(SUPPLIER_ACTIONS.updateSupplierUserLinks, resp.data.links);
      } else if (resp.data.links.some(link => link.rel === 'personal_client_logged_in')) {
        VuexStore.commit(PERSONAL_CLIENT_ACTIONS.updatePersonalClientOrganizationLinks, resp.data.links);
      } else if (resp.data.links.some(link => link.rel === 'business_client_logged_in')) {
        VuexStore.commit(BUSINESS_CLIENT_ACTIONS.updateBusinessClientOrganizationLinks, resp.data.links);
      } else if (resp.data.links.some(link => link.rel === 'event_planner_logged_in')) {
        VuexStore.commit(EVENT_PLANNER_ACTIONS.updateEventPlannerId, resp.data.loggedInEventPlannerInfo.eventPlannerId);
        VuexStore.commit(EVENT_PLANNER_ACTIONS.updateEventPlannerOrganizationLinks, resp.data.links);
      } else if (resp.data.links.some(link => link.rel === 'venue_organization_logged_in')) {
        VuexStore.commit(VENUE_MANAGER_ACTIONS.updateVenueManagerOrganizationLinks, resp.data.links);
      } else if (resp.data.links.some(link => link.rel === 'exhibitor_organization_logged_in')) {
        VuexStore.commit(ExhibitorActions.updateExhibitorOrganizationLinks, resp.data.links);
      } else {
        VuexStore.commit(ADMIN_ACTIONS.updateAdminLinks, resp.data.links);
      }
    }
  } else {
    const apiUri = '/v2/identity_domain/unauthenticated-user/action-links';
    const resp = await axios.get(apiUri);
    if (resp && resp.data) {
      VuexStore.commit(AUTHENTICATION_ACTIONS.updateAuthenticatedUserPossibleActions, resp.data.content);
    }
  }
}
function storeReferenceCode(to) {
  if (to.query.referenceCode) {
    localStorage.setItem('referenceCode', to.query.referenceCode);
  }
}
router.beforeResolve(async (to, from, next) => {
  const expiredTime = localStorage.getItem('expired-time');
  if (expiredTime && +expiredTime > new Date().getTime()) {
    const timeout = +expiredTime - new Date().getTime();
    setTimeout(() => {
      bus.$emit('login#token-is-about-to-expired');
    }, timeout);
  }
  if (!VuexStore.getters.isLoggedIn) {
    await refreshLoggedInInformationFromAccessToken();
  }
  if (PagesDontRequireLogin.includes(to.name)) {
    next();
  } else if (to.path.startsWith('/register/') || to.path.startsWith('/registerFromEventInvitation/')) {
    navigateAfterRegister(to, next);
  } else if (to.path.startsWith('/activate-user')) {
    await navigateAfterActivateUser(to, next);
  }
  if (has(to, 'meta')) {
    if (shouldAuthenticateBeforeOpenPage(to)) {
      await to.meta.authentication.authenticate({
        to,
        from,
        next
      });
    } else {
      next();
    }
    if (shouldAuthorizeBeforeOpenPage(to)) {
      await authorizeUserRoles(next, to, from);
    }
    if (has(to.meta, 'unsupportedMobile') && to.meta.unsupportedMobile) {
      navigateToUnsupportedMobile(from, next, to);
    }
  }
  storeReferenceCode(to);
});
export default router;