import OrganizationProfileAuthentication from '@/crm_domain/organization/authentications/organization-profile-authentication';
import OrganizationProfileAuthorization from '@/crm_domain/organization/authorizations/organization-profile-authorization';
export default [{
  path: '/franchise-owners/:organizationId',
  component: function component() {
    return import('@/crm_domain/organization/components/franchise_owner/FranchiseOwnerOrganizationProfile.vue');
  },
  props: true,
  meta: {
    roles: ['ORGANIZATION_ADMIN']
  },
  children: [{
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: '',
    redirect: 'information'
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    name: 'franchise-owner-profile-information',
    path: 'information',
    component: function component() {
      return import('@/crm_domain/organization/components/franchise_owner/FranchiseOwnerOrganizationInformation.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'users',
    name: 'franchise-owner-profile-users',
    component: function component() {
      return import('@/crm_domain/organization/components/OrganizationUsers.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'settings',
    name: 'franchise-owner-profile-settings',
    component: function component() {
      return import('@/crm_domain/organization/components/OrganizationSettings.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'messages',
    name: 'franchise-owner-profile-messages',
    component: function component() {
      return import('@/communication_domain/organization_message/component/OrganizationMessages.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'bank-account',
    name: 'franchise-owner-profile-bank-account',
    component: function component() {
      return import('@/financial_domain/bank_accounts/OrganizationBankAccountContainer.vue');
    },
    props: true
  }]
}];