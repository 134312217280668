import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import store from '@/core/config/vuex';
const MessagesDetailsAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    const apiUri = decodeURIComponent(store.getters.authenticatedUserPossibleActions.find(link => link.rel === 'get_im_threads').href).replace('{message-id}', to.params.messageId);
    await axios.head(apiUri).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default MessagesDetailsAuthorization;