import BUSINESS_CLIENT_ACTIONS from '@/identity_domain/vuex/business_client/business-client-actions';
const BUSINESS_CLIENT_STORE = {
  state: () => ({
    businessClientOrganizationLinks: []
  }),
  getters: {
    businessClientOrganizationLinks(state) {
      return state.businessClientOrganizationLinks;
    }
  },
  actions: {
    updateBusinessClientOrganizationLinks({
      commit
    }, businessClientOrganizationLinks) {
      commit(BUSINESS_CLIENT_ACTIONS.updateBusinessClientOrganizationLinks, businessClientOrganizationLinks);
    }
  },
  mutations: {
    updateBusinessClientOrganizationLinks(state, businessClientOrganizationLinks) {
      state.businessClientOrganizationLinks = businessClientOrganizationLinks;
    }
  }
};
export default BUSINESS_CLIENT_STORE;