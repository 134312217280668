import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import groupBy from 'lodash/groupBy';
import values from "lodash/values";
import isFunction from "lodash/isFunction";
export var AdminSideBarGroupEnum = {
  ADMINISTRATION: {
    id: 'administration',
    name: 'admin.sidebar_group.administration#text',
    icon: 'mdi-shield-account-variant'
  },
  VENUE_SETTING: {
    id: 'venue-settings',
    name: 'admin.sidebar_group.venue_settings#text',
    icon: 'mdi-domain'
  },
  AI: {
    id: 'ai',
    name: 'admin.sidebar_group.ai#text',
    icon: 'mdi-robot-outline'
  },
  CMS: {
    id: 'cms',
    name: 'admin.sidebar_group.cms#text',
    icon: 'mdi-file-table-box-multiple-outline'
  },
  COMMUNICATION: {
    id: 'communication',
    name: 'admin.sidebar_group.communication#text',
    icon: 'mdi-forum'
  },
  TRANSLATION: {
    id: 'translation',
    name: 'admin.sidebar_group.translation#text',
    icon: 'mdi-translate-variant'
  },
  REPORT: {
    id: 'report',
    name: 'admin.sidebar_group.report#text',
    icon: 'mdi-chart-areaspline'
  },
  PARTNER: {
    id: 'partner',
    name: 'admin.partners#vary',
    icon: 'mdi-account'
  },
  DEMO_TO_PRODUCTION_MIGRATION: {
    id: 'demo-to-production-migration',
    name: 'admin.demo_to_production_migration#vary',
    icon: 'mdi-cog-transfer'
  },
  ADVERTISEMENT: {
    id: 'advertisement',
    name: 'admin.sidebar_group.advertisement#text',
    icon: 'mdi-earth'
  },
  FINANCIAL: {
    id: 'financial',
    name: 'admin.sidebar_group.financial#text',
    icon: 'mdi-finance'
  },
  OTHERS: {
    id: 'OTHERS',
    name: 'admin.sidebar_group.others#text',
    icon: 'mdi-dots-horizontal-circle'
  },
  getList: function getList() {
    return values(this).filter(function (item) {
      return !isFunction(item);
    });
  }
};
export var AdminSideBarItemEnum = {
  ADMIN_USER_ORGANIZATION: {
    id: 'ADMIN_USER_ORGANIZATION',
    pathName: 'admin-user-organizations',
    name: 'admin.user_organization#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_VENUES: {
    id: 'ADMIN_VENUES',
    pathName: 'admin-venues',
    name: 'admin.venues#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_VENUE_LICENSE_REGISTRATION: {
    id: 'ADMIN_VENUE_LICENSE_REGISTRATION',
    pathName: 'admin-venue-license-registration',
    name: 'admin.venue_license_registration#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  COOPAX_ITEM_CATEGORIES: {
    id: 'COOPAX_ITEM_CATEGORIES',
    pathName: 'coopax-item-categories',
    name: 'admin.item_categories#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_SUPPLIER_CATEGORY_GROUPS: {
    id: 'ADMIN_SUPPLIER_CATEGORY_GROUPS',
    pathName: 'admin-supplier-category-groups',
    name: 'admin.supplier_category_groups#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_TERMS_AND_CONDITION: {
    id: 'ADMIN_TERMS_AND_CONDITION',
    pathName: 'admin-terms-and-conditions',
    name: 'admin.terms_and_conditions#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  VENUE_REQUESTS: {
    id: 'VENUE_REQUESTS',
    pathName: 'venue-requests',
    name: 'admin.venue_func_request#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  REVIEW_CONFIGS: {
    id: 'REVIEW_CONFIGS',
    pathName: 'review-configs',
    name: 'admin.review_configs#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  COOPAX_PAYMENT_METHODS: {
    id: 'COOPAX_PAYMENT_METHODS',
    pathName: 'coopax-payment-methods',
    name: 'admin.coopax_payment_methods#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  VENT_ATTENDEE_AGE_GROUPS: {
    id: 'EVENT_ATTENDEE_AGE_GROUPS',
    pathName: 'event-attendee-age-groups',
    name: 'admin.event_attendee_age_groups#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_ORGANIZATION_ROLES: {
    id: 'ADMIN_ORGANIZATION_ROLES',
    pathName: 'admin-organization-roles',
    name: 'admin.organization_roles#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  COMMISSION_SCENARIOS: {
    id: 'COMMISSION_SCENARIOS',
    pathName: 'commission-scenarios',
    name: 'admin.commission_scenarios#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  COOPAX_GOVERNMENT_TAX: {
    id: 'COOPAX_GOVERNMENT_TAX',
    pathName: 'coopax-government-tax',
    name: 'admin.government_tax#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  COOPAX_TERMS_AND_CONDITIONS: {
    id: 'COOPAX_TERMS_AND_CONDITIONS',
    pathName: 'coopax-terms-and-conditions',
    name: 'admin.coopax_terms_and_conditions#vary',
    groupId: AdminSideBarGroupEnum.ADMINISTRATION.id,
    condition: function condition() {
      return true;
    }
  },
  PURPOSES: {
    id: 'PURPOSES',
    pathName: 'purposes',
    name: 'admin.purpose#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  ACTIVITIES: {
    id: 'ACTIVITIES',
    pathName: 'activities',
    name: 'admin.activity#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  BASIC_ITEMS: {
    id: 'BASIC_ITEMS',
    pathName: 'basic-items',
    name: 'admin.basic_items#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  PLACEMENTS: {
    id: 'PLACEMENTS',
    pathName: 'placements',
    name: 'admin.placement#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  PROPERTY_CATEGORIES: {
    id: 'PROPERTY_CATEGORIES',
    pathName: 'property-categories',
    name: 'admin.property#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  TURNOVER_ACCOUNTS: {
    id: 'TURNOVER_ACCOUNTS',
    pathName: 'turnover-accounts',
    name: 'admin.turnover_account#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_BEVERAGE_PACKAGE_CONFIGS: {
    id: 'ADMIN_BEVERAGE_PACKAGE_CONFIGS',
    pathName: 'admin-beverage-package-configs',
    name: 'admin.beverage_package_configs#vary',
    groupId: AdminSideBarGroupEnum.VENUE_SETTING.id,
    condition: function condition() {
      return true;
    }
  },
  EVENT_CREATION_SUGGESTION_PROMPTS: {
    id: 'ADMIN_BEVERAGE_PACKAGE_CONFIGS',
    pathName: 'event-creation-suggestion-prompts',
    name: 'admin.event_creation_suggestion_prompts#vary',
    groupId: AdminSideBarGroupEnum.AI.id,
    condition: function condition() {
      return true;
    }
  },
  BRAZIL_CMS: {
    id: 'BRAZIL_CMS',
    pathName: 'brazil-cms',
    name: 'admin.brazil_cms#vary',
    groupId: AdminSideBarGroupEnum.CMS.id,
    condition: function condition() {
      return true;
    }
  },
  SPAIN_CMS: {
    id: 'SPAIN_CMS',
    pathName: 'spain-cms',
    name: 'admin.spain_cms#vary',
    groupId: AdminSideBarGroupEnum.CMS.id,
    condition: function condition() {
      return true;
    }
  },
  NETHERLANDS_CMS: {
    id: 'NETHERLANDS_CMS',
    pathName: 'netherlands-cms',
    name: 'admin.netherlands_cms#vary',
    groupId: AdminSideBarGroupEnum.CMS.id,
    condition: function condition() {
      return true;
    }
  },
  AUTHORISED_EMAILS: {
    id: 'AUTHORISED_EMAILS',
    pathName: 'authorised-emails',
    name: 'admin.authorised_email_address#vary',
    groupId: AdminSideBarGroupEnum.COMMUNICATION.id,
    condition: function condition(_ref) {
      var isProductionServer = _ref.isProductionServer;
      return !isProductionServer;
    }
  },
  AUTOMATED_EMAILS: {
    id: 'AUTOMATED_EMAILS',
    pathName: 'automated-emails',
    name: 'admin.automated_email#vary',
    groupId: AdminSideBarGroupEnum.COMMUNICATION.id,
    condition: function condition() {
      return true;
    }
  },
  AUTOMATED_MESSAGES: {
    id: 'AUTOMATED_MESSAGES',
    pathName: 'automated-messages',
    name: 'admin.automated_message#vary',
    groupId: AdminSideBarGroupEnum.COMMUNICATION.id,
    condition: function condition() {
      return true;
    }
  },
  RELEASE_NOTES: {
    id: 'RELEASE_NOTES',
    pathName: 'release-notes',
    name: 'admin.release_note#vary',
    groupId: AdminSideBarGroupEnum.COMMUNICATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_UNSUBSCRIBED_EMAILS: {
    id: 'ADMIN_UNSUBSCRIBED_EMAILS',
    pathName: 'admin-unsubscribed-emails',
    name: 'admin.unsubscribed_email#vary',
    groupId: AdminSideBarGroupEnum.COMMUNICATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_TRANSLATION: {
    id: 'ADMIN_TRANSLATION',
    pathName: 'admin-translation',
    name: 'admin.translation#vary',
    groupId: AdminSideBarGroupEnum.TRANSLATION.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_TRANSLATORS: {
    id: 'ADMIN_TRANSLATORS',
    pathName: 'admin-translators',
    name: 'admin.translators#vary',
    groupId: AdminSideBarGroupEnum.TRANSLATION.id,
    condition: function condition() {
      return true;
    }
  },
  PARTNER_REPORTS: {
    id: 'PARTNER_REPORTS',
    pathName: 'partner-reports',
    name: 'admin.partner_reports#vary',
    groupId: AdminSideBarGroupEnum.REPORT.id,
    condition: function condition() {
      return true;
    }
  },
  REVENUE_REPORTS: {
    id: 'REVENUE_REPORTS',
    pathName: 'revenue-reports',
    name: 'admin.revenue_reports#vary',
    groupId: AdminSideBarGroupEnum.REPORT.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_PARTNERS: {
    id: 'ADMIN_PARTNERS',
    pathName: 'admin-partners',
    name: 'admin.partners#vary',
    groupId: AdminSideBarGroupEnum.PARTNER.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_FRANCHISES: {
    id: 'ADMIN_FRANCHISES',
    pathName: 'admin-franchises',
    name: 'admin.franchises#vary',
    groupId: AdminSideBarGroupEnum.PARTNER.id,
    condition: function condition(_ref2) {
      var isSuperAdminRole = _ref2.isSuperAdminRole;
      return isSuperAdminRole;
    }
  },
  PRODUCTION_MIGRATING_VENUE_REQUESTS: {
    id: 'PRODUCTION_MIGRATING_VENUE_REQUESTS',
    pathName: 'production-migrating-venue-requests',
    name: 'admin.migrating_venue_requests#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref3) {
      var isProductionServer = _ref3.isProductionServer;
      return isProductionServer;
    }
  },
  ADMIN_MIGRATE_VENUES: {
    id: 'ADMIN_MIGRATE_VENUES',
    pathName: 'admin-migrate-venues',
    name: 'admin.migrate_venue#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref4) {
      var isDemoServer = _ref4.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_PURPOSE_MIGRATION_REFERENCES: {
    id: 'ADMIN_PURPOSE_MIGRATION_REFERENCES',
    pathName: 'admin-purpose-migration-references',
    name: 'admin.admin_purpose_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref5) {
      var isDemoServer = _ref5.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_TURNOVER_ACCOUNT_MIGRATION_REFERENCES: {
    id: 'ADMIN_TURNOVER_ACCOUNT_MIGRATION_REFERENCES',
    pathName: 'admin-turnover-account-migration-references',
    name: 'admin.admin_turnover_account_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref6) {
      var isDemoServer = _ref6.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_ACTIVITY_MIGRATION_REFERENCES: {
    id: 'ADMIN_ACTIVITY_MIGRATION_REFERENCES',
    pathName: 'admin-activity-migration-references',
    name: 'admin.admin_activity_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref7) {
      var isDemoServer = _ref7.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_BASIC_ITEM_MIGRATION_REFERENCES: {
    id: 'ADMIN_BASIC_ITEM_MIGRATION_REFERENCES',
    pathName: 'admin-basic-item-migration-references',
    name: 'admin.admin_basic_item_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref8) {
      var isDemoServer = _ref8.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_PLACEMENT_MIGRATION_REFERENCES: {
    id: 'ADMIN_PLACEMENT_MIGRATION_REFERENCES',
    pathName: 'admin-placement-migration-references',
    name: 'admin.admin_placement_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref9) {
      var isDemoServer = _ref9.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_PAYMENT_METHOD_MIGRATION_REFERENCES: {
    id: 'ADMIN_PAYMENT_METHOD_MIGRATION_REFERENCES',
    pathName: 'admin-payment-method-migration-references',
    name: 'admin.admin_payment_method_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref10) {
      var isDemoServer = _ref10.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_ITEM_CATEGORY_MIGRATION_REFERENCES: {
    id: 'ADMIN_ITEM_CATEGORY_MIGRATION_REFERENCES',
    pathName: 'admin-item-category-migration-references',
    name: 'admin.admin_item_category_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref11) {
      var isDemoServer = _ref11.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_PROPERTY_SECTION_MIGRATION_REFERENCES: {
    id: 'ADMIN_PROPERTY_SECTION_MIGRATION_REFERENCES',
    pathName: 'admin-property-section-migration-references',
    name: 'admin.admin_property_section_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref12) {
      var isDemoServer = _ref12.isDemoServer;
      return isDemoServer;
    }
  },
  ADMIN_PARTNER_MIGRATION_REFERENCES: {
    id: 'ADMIN_PARTNER_MIGRATION_REFERENCES',
    pathName: 'admin-partner-migration-references',
    name: 'admin.admin_partner_migration_reference#vary',
    groupId: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id,
    condition: function condition(_ref13) {
      var isDemoServer = _ref13.isDemoServer;
      return isDemoServer;
    }
  },
  LOCATION_RANKING: {
    id: 'LOCATION_RANKING',
    pathName: 'location-ranking',
    name: 'admin.featured_venue#vary',
    groupId: AdminSideBarGroupEnum.ADVERTISEMENT.id,
    condition: function condition() {
      return true;
    }
  },
  INFORMATION_REQUEST_EMAIL: {
    id: 'INFORMATION_REQUEST_EMAIL',
    pathName: 'information-request-email',
    name: 'admin.information_request#vary',
    groupId: AdminSideBarGroupEnum.ADVERTISEMENT.id,
    condition: function condition() {
      return true;
    }
  },
  CONTACT_WHATSAPP_PHONE_NUMBER: {
    id: 'CONTACT_WHATSAPP_PHONE_NUMBER',
    pathName: 'contact-whatsapp-phone-number',
    name: 'admin.contact_whatsapp_phone_number#vary',
    groupId: AdminSideBarGroupEnum.ADVERTISEMENT.id,
    condition: function condition() {
      return true;
    }
  },
  RECEIVED_WHATSAPP_MESSAGE_COLLECTION: {
    id: 'RECEIVED_WHATSAPP_MESSAGE_COLLECTION',
    pathName: 'received-whatsapp-message-collection',
    name: 'admin.received_whatsapp_messages#vary',
    groupId: AdminSideBarGroupEnum.ADVERTISEMENT.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_CASHBACK_MANAGEMENT: {
    id: 'ADMIN_CASHBACK_MANAGEMENT',
    pathName: 'admin-cashback-management',
    name: 'admin.venue_cashback_management#vary',
    groupId: AdminSideBarGroupEnum.FINANCIAL.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_MANUAL_PAYMENT_EVENT_INVOICES: {
    id: 'ADMIN_MANUAL_PAYMENT_EVENT_INVOICES',
    pathName: 'admin-manual-payment-event-invoices',
    name: 'admin.manual_payment_event_invoices#vary',
    groupId: AdminSideBarGroupEnum.FINANCIAL.id,
    condition: function condition() {
      return true;
    }
  },
  EVENT_INVOICES: {
    id: 'EVENT_INVOICES',
    pathName: 'event-invoices',
    name: 'admin.event_invoices#vary',
    groupId: AdminSideBarGroupEnum.FINANCIAL.id,
    condition: function condition() {
      return true;
    }
  },
  EVENT_INVOICE_COMMISSIONS: {
    id: 'EVENT_INVOICE_COMMISSIONS',
    pathName: 'event-invoice-commissions',
    name: 'admin.event_invoice_commissions#vary',
    groupId: AdminSideBarGroupEnum.FINANCIAL.id,
    condition: function condition() {
      return true;
    }
  },
  PHOTO: {
    id: 'PHOTO',
    pathName: 'photo',
    name: 'admin.image_repository#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  EVENT_WIZARD: {
    id: 'EVENT_WIZARD',
    pathName: 'event-wizard',
    name: 'admin.event_wizard#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  EVENT_CANCELLATION_REASONS: {
    id: 'EVENT_CANCELLATION_REASONS',
    pathName: 'event-cancellation-reasons',
    name: 'admin.event_cancellation_reasons#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_SIXTEEN_PERSONALITIES: {
    id: 'ADMIN_SIXTEEN_PERSONALITIES',
    pathName: 'admin-sixteen-personalities',
    name: 'admin.sixteen_personalities#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_DISC_PERSONALITIES: {
    id: 'ADMIN_DISC_PERSONALITIES',
    pathName: 'admin-disc-personalities',
    name: 'admin.disc_personalities#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  ADMIN_ENNEAGRAM_PERSONALITIES: {
    id: 'ADMIN_ENNEAGRAM_PERSONALITIES',
    pathName: 'admin-enneagram-personalities',
    name: 'admin.enneagram_personalities#vary',
    groupId: AdminSideBarGroupEnum.OTHERS.id,
    condition: function condition() {
      return true;
    }
  },
  getList: function getList() {
    return values(this).filter(function (item) {
      return !isFunction(item);
    });
  }
};
export function getAdminSidebarGroups(_ref14) {
  var sidebarGroupVisibleConditions = _ref14.sidebarGroupVisibleConditions;
  var groupedAdminSideBarItems = groupBy(AdminSideBarItemEnum.getList(), 'groupId');
  return AdminSideBarGroupEnum.getList().map(function (group) {
    return _objectSpread(_objectSpread({}, group), {}, {
      items: groupedAdminSideBarItems[group.id].filter(function (item) {
        return item.condition({
          sidebarGroupVisibleConditions: sidebarGroupVisibleConditions
        });
      }) || []
    });
  });
}