import AdminAuthentication from '@/core/admin/authentications/admin-authentication';
import AdminAuthorization from '@/core/admin/authorizations/admin-authorization';
import { AdminSideBarGroupEnum } from '@/core/components/navigation-components/constants/AdminSidebarEnum';
export default [{
  path: '/admin',
  name: 'adminDashboard',
  component: function component() {
    return import('@/core/views/AdminView.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization
  },
  children: [{
    path: '',
    name: 'admin-dashboard',
    redirect: 'overview'
  }, {
    name: 'overview',
    path: 'overview',
    component: function component() {
      return import('@/core/admin/Overview.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    path: 'location-properties/:propertyId',
    name: 'venue-property',
    component: function component() {
      return import('@/venue_management_domain/admin_property/components/AdminVenueProperty.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    path: 'property-categories',
    name: 'property-categories',
    component: function component() {
      return import('@/venue_management_domain/admin_property/components/AdminVenueProperties.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    path: 'property-categories/:categoryId',
    component: function component() {
      return import('@/venue_management_domain/admin_property_section/components/PropertyCategory.vue');
    },
    name: 'property-category',
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'activities',
    path: 'activities',
    component: function component() {
      return import('@/property_management_domain/admin_activity/components/AreaActivities.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    path: 'activities/:areaActivityId',
    name: 'activity',
    component: function component() {
      return import('@/property_management_domain/admin_activity/components/AreaActivity.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'basic-items',
    path: 'basic-items',
    component: function component() {
      return import('@/venue_management_domain/admin_basic_items/components/AdminBasicItems.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'basic-item',
    path: 'basic-items/:basicItemId',
    component: function component() {
      return import('@/venue_management_domain/admin_basic_items/components/AdminBasicItem.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'event-wizard',
    path: 'event-wizard',
    component: function component() {
      return import('@/event_booking_domain/admin_wizard/components/AdminEventWizardConfig.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'admin-bite-and-bev',
    path: 'admin-bite-and-bev/:adminBiteAndBevId',
    component: function component() {
      return import('@/event_booking_domain/admin_wizard/components/AdminBiteAndBev.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'admin-break-event-part',
    path: 'admin-break-event-part/:adminBreakEventPartId',
    component: function component() {
      return import('@/event_booking_domain/admin_wizard/components/AdminBreakEventPart.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'placements',
    path: 'placements',
    component: function component() {
      return import('@/property_management_domain/admin_placement/components/AdminPlacements.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    path: 'placements/:placementId',
    name: 'placement',
    component: function component() {
      return import('@/property_management_domain/admin_placement/components/AdminPlacement.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'event-creation-suggestion-prompts',
    path: 'event-creation-suggestion-prompts',
    component: function component() {
      return import('@/event_booking_domain/admin_event_creation_suggestion_prompt_config/EventCreationSuggestionPrompts.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.AI.id
    }
  }, {
    name: 'event-creation-suggestion-prompt',
    path: 'event-creation-suggestion-prompts/:eventCreationSuggestionPromptId',
    component: function component() {
      return import('@/event_booking_domain/admin_event_creation_suggestion_prompt_config/EventCreationSuggestionPrompt.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.AI.id
    }
  }, {
    name: 'release-notes',
    path: 'release-notes',
    component: function component() {
      return import('@/public_domain/admin_release_note/components/AdminReleaseNotes.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'release-notes/:releaseNoteId',
    name: 'release-note',
    props: true,
    component: function component() {
      return import('@/public_domain/admin_release_note/components/AdminReleaseNote.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'automated-emails',
    name: 'automated-emails',
    component: function component() {
      return import('@/communication_domain/admin_automated_email/components/AutomatedEmails.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'automated-emails/:automatedEmailId',
    name: 'automated-email',
    props: true,
    component: function component() {
      return import('@/communication_domain/admin_automated_email/components/AutomatedEmail.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'admin-permissions',
    name: 'admin-permissions',
    props: true,
    component: function component() {
      return import('@/crm_domain/permissions/components/CoopaxPermissions.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'signature',
    name: 'signature',
    props: true,
    component: function component() {
      return import('@/communication_domain/admin_signature/components/Signature.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'venue-requests',
    name: 'venue-requests',
    component: function component() {
      return import('@/crm_domain/organization/components/VenueRequests.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'admin-terms-and-conditions',
    name: 'admin-terms-and-conditions',
    component: function component() {
      return import('@/event_management_domain_new/term_and_condition/admin_term_and_condition_config/AdminTermAndConditionConfigs.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'partner-reports',
    name: 'partner-reports',
    component: function component() {
      return import('@/bi_domain/partner_reports/PartnerReports.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'revenue-reports',
    name: 'revenue-reports',
    component: function component() {
      return import('@/financial_domain/revenue_reports/RevenueReports.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'review-configs',
    name: 'review-configs',
    component: function component() {
      return import('@/public_domain/review_systems/admin_review_configs/components/AdminReviewConfigs.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'admin-sixteen-personalities',
    name: 'admin-sixteen-personalities',
    component: function component() {
      return import('@/event_team_management_domain/admin_character_personalities/components/AdminSixteenPersonalities.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.OTHERS.id
    }
  }, {
    path: 'admin-disc-personalities',
    name: 'admin-disc-personalities',
    component: function component() {
      return import('@/event_team_management_domain/admin_character_personalities/components/AdminDISCPersonalityOptions.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.OTHERS.id
    }
  }, {
    path: 'admin-enneagram-personalities',
    name: 'admin-enneagram-personalities',
    component: function component() {
      return import('@/event_team_management_domain/admin_character_personalities/components/AdminEnneagramPersonalityOptions.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.OTHERS.id
    }
  },
  // We will hide this sidebar item because TB-5329
  // {
  //   path: 'admin-payment-methods',
  //   name: 'admin-payment-methods',
  //   component: () => import('@/financial_domain/admin_payment_methods/components/AdminPaymentMethods.vue'),
  //   meta: {
  //     roles: ['ADMIN'],
  //     authentication: AdminAuthentication,
  //     authorization: AdminAuthorization,
  //     group: AdminSideBarGroupEnum.ADMINISTRATION.id,
  //   },
  // },
  {
    path: 'coopax-payment-methods',
    name: 'coopax-payment-methods',
    component: function component() {
      return import('@/financial_domain/coopax_payment_methods/components/CoopaxPaymentMethods.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'admin-payment-methods/:paymentMethodId',
    name: 'admin-payment-method',
    component: function component() {
      return import('@/financial_domain/admin_payment_methods/components/AdminPaymentMethod.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    },
    props: true
  }, {
    path: 'admin-supplier-category-groups',
    name: 'admin-supplier-category-groups',
    component: function component() {
      return import('@/supplier_domain/admin_supplier_category_group/components/AdminSupplierCategoryGroups.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'admin-supplier-category-group/:supplierCategoryGroupId',
    name: 'admin-supplier-category-group',
    component: function component() {
      return import('@/supplier_domain/admin_supplier_category_group/components/AdminSupplierCategoryGroup.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    },
    props: true
  }, {
    path: 'admin-supplier-category/:supplierCategoryId',
    name: 'admin-supplier-category',
    component: function component() {
      return import('@/supplier_domain/admin_supplier_category_group/components/AdminSupplierCategory.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    },
    props: true
  }, {
    path: 'coopax-item-categories',
    name: 'coopax-item-categories',
    component: function component() {
      return import('@/catering_domain/admin_item_categories/components/CoopaxItemCategories.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'admin-partners',
    name: 'admin-partners',
    component: function component() {
      return import('@/crm_domain/admin_partners/components/AdminPartners.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.PARTNER.id
    }
  }, {
    path: 'admin-partners/:partnerId',
    name: 'admin-partner',
    props: true,
    component: function component() {
      return import('@/crm_domain/admin_partners/components/AdminPartner.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.PARTNER.id
    }
  }, {
    path: 'admin-franchises',
    name: 'admin-franchises',
    component: function component() {
      return import('@/crm_domain/admin_franchises/components/AdminFranchises.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.PARTNER.id
    }
  }, {
    path: 'admin-franchises/:franchiseId',
    name: 'admin-franchise',
    props: true,
    component: function component() {
      return import('@/crm_domain/admin_franchises/components/AdminFranchise.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.PARTNER.id
    }
  }, {
    name: 'automated-messages',
    path: 'automated-messages',
    component: function component() {
      return import('@/communication_domain/admin_automated_message/components/AutomatedMessages.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'automated-messages/:automatedMessageId',
    name: 'automated-message',
    component: function component() {
      return import('@/communication_domain/admin_automated_message/components/AutomatedMessage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    name: 'turnover-accounts',
    path: 'turnover-accounts',
    component: function component() {
      return import('@/financial_domain/admin_turnover_account/components/AdminTurnoverAccounts.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'turnover-account',
    path: 'turnover-accounts/:turnoverAccountId',
    component: function component() {
      return import('@/financial_domain/admin_turnover_account/components/AdminTurnoverAccount.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'turnover-account-category',
    path: 'turnover-account-categories/:turnoverAccountCategoryId',
    component: function component() {
      return import('@/financial_domain/admin_turnover_account/components/AdminTurnoverAccountCategory.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'users',
    path: 'users',
    component: function component() {
      return import('@/crm_domain/users/components/admin_manage_users/UserListForAdmin.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    name: 'user-profile-for-admin',
    path: 'users/:userProfileId',
    component: function component() {
      return import('@/crm_domain/users/components/admin_manage_users/UserProfileForAdmin.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    },
    props: true,
    children: [{
      path: '',
      redirect: 'information'
    }, {
      meta: {
        authentication: AdminAuthentication,
        authorization: AdminAuthorization
      },
      path: 'information',
      name: 'user-information-for-admin',
      component: function component() {
        return import('@/crm_domain/users/components/admin_manage_users/UserInformationForAdmin.vue');
      }
    }, {
      meta: {
        authentication: AdminAuthentication,
        authorization: AdminAuthorization
      },
      name: 'user-organizations-for-admin',
      path: 'organizations',
      component: function component() {
        return import('@/crm_domain/users/components/admin_manage_users/UserOrganizationsForAdmin.vue');
      }
    }, {
      meta: {
        authentication: AdminAuthentication,
        authorization: AdminAuthorization
      },
      name: 'user-settings-for-admin',
      path: 'settings',
      component: function component() {
        return import('@/crm_domain/users/components/admin_manage_users/UserSettingsForAdmin.vue');
      }
    }]
  }, {
    name: 'admin-user-organizations',
    path: 'user-organizations',
    component: function component() {
      return import('@/identity_domain/admin_user_organizations/components/AdminUserOrganizations.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    name: 'admin-venues',
    path: 'admin-venues',
    component: function component() {
      return import('@/venue_management_domain/admin_venues/components/AdminVenues.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: '/admin/event-attendee-age-groups',
    name: 'event-attendee-age-groups',
    component: function component() {
      return import('@/event_management_domain_new/admin_event_attendee_age_groups/AdminEventAttendeeAgeGroups.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: '/admin/event-attendee-age-group/:eventAttendeeAgeGroupId',
    name: 'event-attendee-age-group',
    component: function component() {
      return import('@/event_management_domain_new/admin_event_attendee_age_groups/AdminEventAttendeeAgeGroup.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    },
    props: true
  }, {
    name: 'location-ranking',
    path: 'location-ranking',
    component: function component() {
      return import('@/event_booking_domain/admin_featured_venue/components/LocationRanking.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADVERTISEMENT.id
    }
  }, {
    name: 'admin-cashback-management',
    path: 'admin-cashback-management',
    component: function component() {
      return import('@/financial_domain/admin_cashback_management/AdminCashbackManagement.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.FINANCIAL.id
    }
  }, {
    name: 'information-request-email',
    path: 'information-request-email',
    component: function component() {
      return import('@/communication_domain/marketing_team_member/components/InformationRequest.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADVERTISEMENT.id
    }
  }, {
    name: 'contact-whatsapp-phone-number',
    path: 'contact-whatsapp-phone-number',
    component: function component() {
      return import('@/communication_domain/coopax_whatsapp_config/components/CoopaxWhatsappConfig.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADVERTISEMENT.id
    }
  }, {
    name: 'received-whatsapp-message-collection',
    path: 'received-whatsapp-message-collection',
    component: function component() {
      return import('@/whatsapp_integration_domain/components/ReceivedWhatsappMessageCollection.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADVERTISEMENT.id
    }
  }, {
    path: '/brazil-cms',
    name: 'brazil-cms',
    component: function component() {
      return import('@/cms_domain/brazil_cms/components/BrazilCMS.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/brazil-cms/organization-extra-information-settings',
    name: 'brazil-organization-extra-information-settings',
    component: function component() {
      return import('@/cms_domain/brazil_cms/components/BrazilOrganizationExtraInformationSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/brazil-cms/address-settings',
    name: 'brazil-address-settings',
    component: function component() {
      return import('@/cms_domain/brazil_cms/components/BrazilAddressSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'adding-new-brazil-venue-license-package',
    path: '/brazil-cms/adding-new-brazil-venue-license-package',
    component: function component() {
      return import('@/cms_domain/brazil_cms/components/venue_license_packages/AddingNewBrazilVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'editing-brazil-venue-license-package',
    path: '/brazil-cms/editing-brazil-venue-license-package/:venueLicensePackageId',
    component: function component() {
      return import('@/cms_domain/brazil_cms/components/venue_license_packages/EditingBrazilVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/spain-cms',
    name: 'spain-cms',
    component: function component() {
      return import('@/cms_domain/spain_cms/components/SpainCMS.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/spain-cms/organization-extra-information-settings',
    name: 'spain-organization-extra-information-settings',
    component: function component() {
      return import('@/cms_domain/spain_cms/components/SpainOrganizationExtraInformationSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/spain-cms/address-settings',
    name: 'spain-address-settings',
    component: function component() {
      return import('@/cms_domain/spain_cms/components/SpainAddressSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'adding-new-spain-venue-license-package',
    path: '/spain-cms/adding-new-spain-venue-license-package',
    component: function component() {
      return import('@/cms_domain/spain_cms/components/venue_license_packages/AddingNewSpainVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'editing-spain-venue-license-package',
    path: '/spain-cms/editing-spain-venue-license-package/:venueLicensePackageId',
    component: function component() {
      return import('@/cms_domain/spain_cms/components/venue_license_packages/EditingSpainVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/netherlands-cms',
    name: 'netherlands-cms',
    component: function component() {
      return import('@/cms_domain/netherlands_cms/components/NetherlandsCMS.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/netherlands-cms/organization-extra-information-settings',
    name: 'netherlands-organization-extra-information-settings',
    component: function component() {
      return import('@/cms_domain/netherlands_cms/components/NetherlandsOrganizationExtraInformationSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    path: '/netherlands-cms/address-settings',
    name: 'netherlands-address-settings',
    component: function component() {
      return import('@/cms_domain/netherlands_cms/components/NetherlandsAddressSettings.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'adding-new-netherlands-venue-license-package',
    path: '/netherlands-cms/adding-new-netherlands-venue-license-package',
    component: function component() {
      return import('@/cms_domain/netherlands_cms/components/venue_license_packages/AddingNewNetherlandsVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'editing-netherlands-venue-license-package',
    path: '/netherlands-cms/editing-netherlands-venue-license-package/:venueLicensePackageId',
    component: function component() {
      return import('@/cms_domain/netherlands_cms/components/venue_license_packages/EditingNetherlandsVenueLicensePackage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.CMS.id
    }
  }, {
    name: 'purposes',
    path: 'purposes',
    component: function component() {
      return import('@/venue_management_domain/admin_purpose/components/Purposes.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    path: 'purposes/:purposeId',
    component: function component() {
      return import('@/venue_management_domain/admin_purpose/components/Purpose.vue');
    },
    props: true,
    name: 'purpose',
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'list-admin',
    path: 'list-admin',
    component: function component() {
      return import('@/crm_domain/admin_users/components/AdminUsers.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    path: ':id/information',
    name: 'admin-profile',
    component: function component() {
      return import('@/crm_domain/admin_users/components/AdminProfile.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    path: 'authorised-emails',
    name: 'authorised-emails',
    component: function component() {
      return import('@/communication_domain/authorized_email/components/AuthorisedEmails.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'admin-unsubscribed-emails',
    name: 'admin-unsubscribed-emails',
    component: function component() {
      return import('@/communication_domain/admin_unsubscribe_email/AdminUnsubscribedEmailManager.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    path: 'authorised-emails/:authorizedEmailId',
    name: 'authorised-email',
    props: true,
    component: function component() {
      return import('@/communication_domain/authorized_email/components/AuthorisedEmail.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.COMMUNICATION.id
    }
  }, {
    name: 'photo',
    path: 'photo',
    component: function component() {
      return import('@/core/admin/photo/AdminManageImageRepository.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.VENUE_SETTING.id
    }
  }, {
    name: 'languages',
    path: 'languages',
    component: function component() {
      return import('@/crm_domain/languages/components/AdminLanguages.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'language',
    path: 'languages/:languageId',
    component: function component() {
      return import('@/crm_domain/languages/components/AdminLanguage.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'event-cancellation-reasons',
    path: 'event-cancellation-reasons',
    component: function component() {
      return import('@/event_management_domain_new/event_cancellation_reasons/components/EventCancellationReasons.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'admin-beverage-package-configs',
    path: 'admin-beverage-package-configs',
    component: function component() {
      return import('@/catering_domain/admin_beverage_packages/components/AdminBeveragePackageConfigContainer.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'event-cancellation-reason',
    path: 'event-cancellation-reason/:reasonId',
    component: function component() {
      return import('@/event_management_domain_new/event_cancellation_reasons/components/EventCancellationReason.vue');
    },
    props: true,
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization
    }
  }, {
    name: 'admin-purpose-migration-references',
    path: 'admin-purpose-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_purposes/AdminPurposeMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-turnover-account-migration-references',
    path: 'admin-turnover-account-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_turnover_accounts/AdminTurnoverAccountMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-activity-migration-references',
    path: 'admin-activity-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_activities/AdminActivitiesMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-basic-item-migration-references',
    path: 'admin-basic-item-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_basic_items/AdminBasicItemMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-placement-migration-references',
    path: 'admin-placement-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_placements/AdminPlacementMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-payment-method-migration-references',
    path: 'admin-payment-method-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_payment_methods/AdminPaymentMethodMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-item-category-migration-references',
    path: 'admin-item-category-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_item_categories/AdminItemCategoryMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-property-section-migration-references',
    path: 'admin-property-section-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_property_sections/AdminPropertySectionMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-partner-migration-references',
    path: 'admin-partner-migration-references',
    component: function component() {
      return import('@/demo_to_production_migration/admin_partners/AdminPartnerMigrationReferences.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'admin-migrate-venues',
    path: 'admin-migrate-venues',
    component: function component() {
      return import('@/demo_to_production_migration/venues/components/AdminMigrateVenues.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    name: 'production-migrating-venue-requests',
    path: 'production-migrating-venue-requests',
    component: function component() {
      return import('@/demo_to_production_migration/production_migrating_venue_requests/ProductionMigratingVenueRequests.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.DEMO_TO_PRODUCTION_MIGRATION.id
    }
  }, {
    path: 'admin-translators',
    name: 'admin-translators',
    component: function component() {
      return import('@/i18n_translation/translator_config/TranslatorsContainer.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.TRANSLATION.id
    }
  }, {
    path: 'admin-organization-roles',
    name: 'admin-organization-roles',
    component: function component() {
      return import('@/crm_domain/admin_organization_roles/AdminOrganizationRoles.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }, {
    path: 'coopax-government-tax',
    name: 'coopax-government-tax',
    component: function component() {
      return import('@/financial_domain/government_tax/CoopaxGovernmentTax.vue');
    },
    meta: {
      roles: ['ADMIN'],
      authentication: AdminAuthentication,
      authorization: AdminAuthorization,
      group: AdminSideBarGroupEnum.ADMINISTRATION.id
    }
  }]
}, {
  path: '/admin/admin-translation',
  name: 'admin-translation',
  component: function component() {
    return import('@/i18n_translation/ui_translation/UITranslation.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.TRANSLATION.id
  }
}, {
  path: '/admin/admin-invoices',
  name: 'admin-invoices',
  component: function component() {
    return import('@/financial_domain/customer_paid_invoices/components/AdminEventInvoices.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.ADMINISTRATION.id
  }
}, {
  path: '/admin/manual-payment-event-invoices',
  name: 'admin-manual-payment-event-invoices',
  component: function component() {
    return import('@/financial_domain/customer_paid_invoices/components/AdminManualPaymentInvoices.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.FINANCIAL.id
  }
}, {
  path: '/admin/event-invoices',
  name: 'event-invoices',
  component: function component() {
    return import('@/financial_domain/customer_paid_invoices/components/AdminEventInvoices.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.FINANCIAL.id
  }
}, {
  path: '/admin/event-invoice-commissions',
  name: 'event-invoice-commissions',
  component: function component() {
    return import('@/financial_domain/invoice_commisisons/components/AdminEventInvoiceCommissions.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.FINANCIAL.id
  }
}, {
  path: '/admin/admin-venue-license-registration',
  name: 'admin-venue-license-registration',
  component: function component() {
    return import('@/financial_domain/admin_venue_license_registration/AdminVenueLicenseRegistration.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.ADMINISTRATION.id
  }
}, {
  path: '/admin/commission-scenarios',
  name: 'commission-scenarios',
  component: function component() {
    return import('@/financial_domain/commission_scenarios/components/CommissionScenarios.vue');
  },
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.ADMINISTRATION.id
  }
}, {
  path: '/admin/commission-scenarios/:scenarioId',
  name: 'commission-scenario',
  component: function component() {
    return import('@/financial_domain/commission_scenarios/components/CommissionScenario.vue');
  },
  props: true,
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.ADMINISTRATION.id
  }
}, {
  path: '/admin/coopax-terms-and-conditions',
  name: 'coopax-terms-and-conditions',
  component: function component() {
    return import('@/venue_management_domain/coopax_terms_and_conditions/components/CoopaxTermsAndConditions.vue');
  },
  props: true,
  meta: {
    roles: ['ADMIN'],
    authentication: AdminAuthentication,
    authorization: AdminAuthorization,
    group: AdminSideBarGroupEnum.ADMINISTRATION.id
  }
}];