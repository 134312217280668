import has from 'lodash/has';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
const Locale = 'locale';
const AUTHENTICATION_STORE = {
  state: () => ({
    /**
     * User information contain these values:
     * user name, full name
     * accountType (deprecated), city, email, phoneNo, photoUrl(user)
     */
    user: null,
    currentOrganization: null,
    /**
     * User settings contain these values:
     * dateFormat, languageCode, numberFormat, showPriceInclVat, username
     */
    userSettings: null,
    /**
     * Show price incl vat is a part of user settings, but we don't want to access whole user settings every time
     * So we split it to another property in state
     */
    showPriceInclVat: true,
    /**
     * When use log in and
     * 1. User has only one organization
     * 2. Organization has only one event
     * 3. Organization is event planner
     *
     * We need to navigate to this only event instead of home page
     * So we store the event id here to check
     */
    eventIdInCaseOrganizationHasOnlyOneEvent: null,
    /**
     * We can show login modal from many places: header, footer, event page
     * So we use a share state for them
     */
    showLoginModal: false,
    /**
     * When user have many different organizations
     * They can choose to log in / switch between these organizations
     * User can choose organization when
     * 1. After login
     * 2. Switch from menu
     */
    showLoginToOrganizationModal: false,
    /**
     * We can show login modal from many places: header, footer, event page
     * So we use a share state for them
     */
    showRegisterModal: false,
    /**
     * When user is creating an offline event, they can register or login for requesting quotation
     * In this case we need to send the offlineEvent to backend
     * In theory we can get this from local history, but let it here for now
     */
    offlineEvent: {},
    /**
     * User can register new organization from User Profile -> Organizations -> Register New ORG button
     */
    showRegisterOrganizationFromUserProfileModal: false,
    /**
     * get list organizations in User Profile -> Organizations when register new organization successfully
     */
    reloadOrganizationListInUserProfile: false,
    /**
     * in case not logged in any user, we will use the first preferred browser language
     */
    preferredBrowserLanguageCode: '',
    /**
     * which route will go to after login
     */
    nextRoute: null,
    /**
     * the first time we access to Coopax, we will use country code from user browser
     */
    countryCodeFromUserBrowser: null,
    /**
     * Original admin timezone
     */
    originalAdminTimezone: {},
    /**
     * to know if we should show the new release note notification modal to the user or not
     */
    shouldShowReleaseNoteNotification: false,
    /**
     * number of planned event of an org, to check if we will show event dashboard on sidebar
     */
    plannedEventNumber: null,
    /**
     * Event planner request quote wizard visible
     */
    epRequestQuoteWizardVisible: false,
    /**
     * After login, there is some authentication actions that user can do, which define in links of /users/me API
     * We store this because there are many places user can work with authentication in application
     */
    authenticatedUserPossibleActions: [],
    isTranslatorMode: false
  }),
  mutations: {
    startReloadOrganizationListInUserProfile(state) {
      state.reloadOrganizationListInUserProfile = true;
    },
    stopReloadOrganizationListInUserProfile(state) {
      state.reloadOrganizationListInUserProfile = false;
    },
    openRegisterOrganizationFromUserProfileModal(state) {
      state.showRegisterOrganizationFromUserProfileModal = true;
    },
    closeRegisterOrganizationFromUserProfileModal(state) {
      state.showRegisterOrganizationFromUserProfileModal = false;
    },
    updateUserSettings(state, userSettings) {
      state.userSettings = userSettings;
    },
    updateOriginalAdminTimezone(state, originalAdminTimezone) {
      state.originalAdminTimezone = originalAdminTimezone;
    },
    resetAdminTimezoneToOriginalOne(state) {
      if (state.originalAdminTimezone && state.originalAdminTimezone.id) {
        state.userSettings.timezone = state.originalAdminTimezone;
        state.userSettings.timezoneId = state.originalAdminTimezone.id;
      }
    },
    updateShowPriceInclVat(state, showPriceInclVat) {
      state.showPriceInclVat = showPriceInclVat;
    },
    updateCurrentOrganization(state, currentOrganization) {
      state.currentOrganization = currentOrganization;
    },
    clearCurrentOrganization(state) {
      state.currentOrganization = null;
    },
    clearUserSettings(state) {
      state.userSettings = {
        dateFormat: 'nl',
        languageCode: localStorage.getItem(Locale),
        numberFormat: 'nl',
        username: null
      };
    },
    updateUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    clearNextRoute(state) {
      state.nextRoute = null;
    },
    updateEventIdInCaseOrganizationHasOnlyOneEvent(state, eventId) {
      state.eventIdInCaseOrganizationHasOnlyOneEvent = eventId;
    },
    clearEventIdInCaseOrganizationHasOnlyOneEvent(state) {
      state.eventIdInCaseOrganizationHasOnlyOneEvent = null;
    },
    openLoginModal(state, nextRoute = null) {
      state.showLoginModal = true;
      state.nextRoute = nextRoute;
    },
    closeLoginModal(state) {
      state.showLoginModal = false;
    },
    openLoginToOrganizationModal(state) {
      state.showLoginToOrganizationModal = true;
    },
    closeLoginToOrganizationModal(state) {
      state.showLoginToOrganizationModal = false;
    },
    openRegisterModal(state) {
      state.showRegisterModal = true;
    },
    closeRegisterModal(state) {
      state.showRegisterModal = false;
    },
    updateOfflineEvent(state, offlineEvent) {
      state.offlineEvent = offlineEvent;
    },
    updateEPRequestQuoteWizardVisible(state, epRequestQuoteWizardVisible) {
      state.epRequestQuoteWizardVisible = epRequestQuoteWizardVisible;
    },
    updatePreferredBrowserLanguageCode(state, preferredBrowserLanguageCode) {
      state.preferredBrowserLanguageCode = preferredBrowserLanguageCode;
    },
    updateCountryCodeFromUserBrowser(state, countryCodeFromUserBrowser) {
      state.countryCodeFromUserBrowser = countryCodeFromUserBrowser;
    },
    updateShouldShowReleaseNoteNotification(state, shouldShowReleaseNoteNotification) {
      state.shouldShowReleaseNoteNotification = shouldShowReleaseNoteNotification;
    },
    logOut(state) {
      state.user = null;
      state.currentOrganization = null;
      state.userSettings = {
        dateFormat: 'nl',
        languageCode: localStorage.getItem(Locale),
        numberFormat: 'nl',
        username: null
      };
    },
    updatePlannedEventNumber(state, plannedEventNumber) {
      state.plannedEventNumber = plannedEventNumber;
    },
    updateAuthenticatedUserPossibleActions(state, authenticatedUserPossibleActions) {
      state.authenticatedUserPossibleActions = authenticatedUserPossibleActions;
    },
    updateTranslatorMode(state, mode) {
      state.isTranslatorMode = mode;
    }
  },
  getters: {
    userTimezone(state) {
      return state.userSettings.timezone;
    },
    currentOrganization(state) {
      return state.currentOrganization;
    },
    shouldFetchOrganizationsInUserProfile(state) {
      return state.reloadOrganizationListInUserProfile;
    },
    showRegisterOrganizationFromUserProfileModal(state) {
      return state.showRegisterOrganizationFromUserProfileModal;
    },
    numberFormat(state) {
      return state.userSettings.numberFormat;
    },
    showLoginModal(state) {
      return state.showLoginModal;
    },
    showLoginToOrganizationModal(state) {
      return state.showLoginToOrganizationModal;
    },
    showRegisterModal(state) {
      return state.showRegisterModal;
    },
    isAdmin(state) {
      return state.user && state.user.isLoggedInAsAdmin;
    },
    isSuperAdminRole(state) {
      return state.user && state.user.isSuperAdminRole;
    },
    isAdminRole(state) {
      return state.user && state.user.isNormalAdminRole;
    },
    isHelpdeskRole(state) {
      return state.user && state.user.isHelpdeskRole;
    },
    isLoggedIn(state) {
      return !!state.user;
    },
    isLoggedInToOrganization(state) {
      return !!(state.user && state.currentOrganization);
    },
    offlineEvent(state) {
      return state.offlineEvent;
    },
    isVenueManager(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'venue_organization_logged_in');
    },
    isEventPlanner(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'event_planner_logged_in');
    },
    isSupplier(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'supplier_logged_in');
    },
    isClient(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'personal_client_logged_in' || link.rel === 'business_client_logged_in');
    },
    isExhibitor(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'exhibitor_organization_logged_in');
    },
    isTranslator(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'update_one_translation_key');
    },
    isTranslatorMode(state) {
      return state.isTranslatorMode;
    },
    currentUser(state) {
      return state.user;
    },
    currentOrganizationId(state) {
      return state.currentOrganization && state.currentOrganization.id;
    },
    isPersonalAccount(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'personal_client_logged_in');
    },
    isBusinessAccount(state) {
      return state.authenticatedUserPossibleActions.some(link => link.rel === 'business_client_logged_in');
    },
    userLanguage(state) {
      return state.userSettings && state.userSettings.languageCode || localStorage.getItem(Locale) || state.preferredBrowserLanguageCode;
    },
    userSettings(state) {
      return state.userSettings;
    },
    shouldShowPriceInclVat(state) {
      return state.showPriceInclVat;
    },
    nextRoute(state) {
      return state.nextRoute;
    },
    countryCodeFromUserBrowser(state) {
      return state.countryCodeFromUserBrowser;
    },
    shouldShowReleaseNoteNotification(state) {
      return state.shouldShowReleaseNoteNotification;
    },
    plannedEventNumber(state) {
      return state.plannedEventNumber;
    },
    epRequestQuoteWizardVisible(state) {
      return state.epRequestQuoteWizardVisible;
    },
    authenticatedUserPossibleActions(state) {
      return state.authenticatedUserPossibleActions;
    },
    loggedInOrganization(state) {
      return state.loggedInOrganization;
    }
  },
  actions: {
    /**
     * When admin login to any organization and go back to admin page
     * We need to reset admin timezone to original one
     * @param commit
     * @param userSettings
     */
    resetAdminTimezoneToOriginalOne({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.resetAdminTimezoneToOriginalOne);
    },
    /**
     * When admin login to any organization
     * We need to update the original admin timezone
     * @param commit
     * @param originalAdminTimezone
     */
    updateOriginalAdminTimezone({
      commit
    }, originalAdminTimezone) {
      commit(AUTHENTICATION_ACTIONS.updateOriginalAdminTimezone, originalAdminTimezone);
    },
    /**
     * After login or change user settings - language, date config and price config
     * We need to update the user settings in store, so all the application will run with new settings
     * @param commit
     * @param userSettings
     */
    updateUserSettings({
      commit
    }, userSettings) {
      // for login user, we use showPriceInclVat config from userSettings
      // Admin user doesn't have userSettings
      if (has(userSettings, 'showPriceInclVat')) {
        commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, userSettings.showPriceInclVat);
      } else {
        commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, localStorage.getItem('showPriceInclVat') === 'true');
      }
      commit(AUTHENTICATION_ACTIONS.updateUserSettings, userSettings);
    },
    /**
     * When switch between price incl vat and price excl vat in user settings or in footer
     * We need to update the new setting in store, to make the application run with new setting
     * @param commit
     * @param showPriceInclVat
     */
    updateShowPriceInclVat({
      commit
    }, showPriceInclVat) {
      commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, showPriceInclVat);
    },
    updateCurrentOrganization({
      commit
    }, currentOrganization) {
      commit(AUTHENTICATION_ACTIONS.updateCurrentOrganization, currentOrganization);
      localStorage.setItem('organizationId', `${currentOrganization.id}`);
    },
    clearCurrentOrganization({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.clearCurrentOrganization, null);
      localStorage.removeItem('organizationId');
    },
    /**
     * When log out, we need to clear user setting
     * @param commit
     */
    clearUserSettings({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.clearUserSettings, null);
    },
    /**
     * When log in, we need to store the user information (not user profile) - included user name
     * @param commit
     * @param user
     */
    updateUser({
      commit
    }, user) {
      commit(AUTHENTICATION_ACTIONS.updateUser, user);
      localStorage.setItem('username', `${user.username}`);
    },
    /**
     * When log out, we need to clear the user data
     * @param commit
     */
    clearUser({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.clearUser, null);
      localStorage.removeItem('username');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expired-time');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refresh-access-token-link');
    },
    /**
     * When access to organization, we need to clear the next route
     * @param commit
     */
    clearNextRoute({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.clearNextRoute);
    },
    /**
     * When log in, we need to check and set the event id in case
     * 1. User has only one organization
     * 2. Organization has only one event
     * 3. Organization is event planner
     * @param eventId
     * @param commit
     */
    updateEventIdInCaseOrganizationHasOnlyOneEvent({
      commit
    }, eventId) {
      commit(AUTHENTICATION_ACTIONS.updateEventIdInCaseOrganizationHasOnlyOneEvent, eventId);
    },
    /**
     * After login and navigate to event page
     * We need to clear the event id in store
     * @param commit
     */
    clearEventIdInCaseOrganizationHasOnlyOneEvent({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.clearEventIdInCaseOrganizationHasOnlyOneEvent, null);
    },
    /**
     * Open login modal to login
     * In case we access to any link that require login
     * We use nextRoute to know where we will go next
     * @param commit
     * @param nextRoute
     */
    openLoginModal({
      commit
    }, nextRoute) {
      commit(AUTHENTICATION_ACTIONS.openLoginModal, nextRoute);
    },
    /**
     * Close login modal
     */
    closeLoginModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.closeLoginModal);
    },
    /**
     * Open login to organization modal
     * @param commit
     */
    openLoginToOrganizationModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.openLoginToOrganizationModal);
    },
    /**
     * Close login to organization modal
     * @param commit
     */
    closeLoginToOrganizationModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.closeLoginToOrganizationModal);
    },
    /**
     * Open register modal
     * @param state
     */
    openRegisterModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.openRegisterModal);
    },
    /**
     * Close register modal
     * @param state
     */
    closeRegisterModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.closeRegisterModal);
    },
    /**
     * Update offline event from offline event page
     * To use in register or login page
     * @param state
     * @param offlineEvent
     */
    updateOfflineEvent({
      commit
    }, offlineEvent) {
      commit(AUTHENTICATION_ACTIONS.updateOfflineEvent, offlineEvent);
    },
    updateEPRequestQuoteWizardVisible({
      commit
    }, epRequestQuoteWizardVisible) {
      commit(AUTHENTICATION_ACTIONS.updateEPRequestQuoteWizardVisible, epRequestQuoteWizardVisible);
    },
    /**
     * clear offline event from offline event page
     * To use in register or login page
     * @param state
     */
    clearOfflineEvent({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.updateOfflineEvent, null);
    },
    /**
     * Open register new organization modal from User Profile -> Organizations
     * @param commit
     */
    openRegisterOrganizationFromUserProfileModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.openRegisterOrganizationFromUserProfileModal);
    },
    /**
     * close register new organization modal from User Profile -> Organizations
     * @param commit
     */
    closeRegisterOrganizationFromUserProfileModal({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.closeRegisterOrganizationFromUserProfileModal);
    },
    /**
     * update the reloadOrganizationListInUserProfile when register organization success
     * when register organization success -> true
     * @param commit
     */
    startReloadOrganizationListInUserProfile({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.startReloadOrganizationListInUserProfile);
    },
    /**
     * update the reloadOrganizationListInUserProfile when register organization success
     * when done fetching the organization list -> false
     * @param commit
     */
    stopReloadOrganizationListInUserProfile({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.stopReloadOrganizationListInUserProfile);
    },
    /**
     * update the preferred browser language when open the application
     * @param commit
     * @param preferredBrowserLanguageCode
     */
    updatePreferredBrowserLanguageCode({
      commit
    }, preferredBrowserLanguageCode) {
      commit(AUTHENTICATION_ACTIONS.updatePreferredBrowserLanguageCode, preferredBrowserLanguageCode);
    },
    /**
     * update country code from user browser when open the application in the first time
     * @param commit
     * @param preferredBrowserLanguageCode
     */
    updateCountryCodeFromUserBrowser({
      commit
    }, countryCodeFromUserBrowser) {
      commit(AUTHENTICATION_ACTIONS.updateCountryCodeFromUserBrowser, countryCodeFromUserBrowser);
    },
    logOut({
      commit
    }) {
      commit(AUTHENTICATION_ACTIONS.logOut);
    },
    updateShouldShowReleaseNoteNotification({
      commit
    }, shouldShowReleaseNoteNotification) {
      commit(AUTHENTICATION_ACTIONS.updateShouldShowReleaseNoteNotification, shouldShowReleaseNoteNotification);
    },
    updatePlannedEventNumber({
      commit
    }, plannedEventNumber) {
      commit(AUTHENTICATION_ACTIONS.updatePlannedEventNumber, plannedEventNumber);
    },
    updateAuthenticatedUserPossibleActions({
      commit
    }, possibleActions) {
      commit(AUTHENTICATION_ACTIONS.updateAuthenticatedUserPossibleActions, possibleActions);
    },
    updateTranslatorMode({
      commit
    }, mode) {
      commit(AUTHENTICATION_ACTIONS.updateTranslatorMode, mode);
    }
  }
};
export default AUTHENTICATION_STORE;