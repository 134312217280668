import FRANCHISE_OWNER_ACTIONS from '@/identity_domain/vuex/franchise_owner/franchise-owner-actions';
var FRANCHISE_OWNER_STORE = {
  state: function state() {
    return {
      franchiseOwnerOrganizationLinks: []
    };
  },
  getters: {
    franchiseOwnerOrganizationLinks: function franchiseOwnerOrganizationLinks(state) {
      return state.franchiseOwnerOrganizationLinks;
    }
  },
  actions: {
    updateFranchiseOwnerOrganizationLinks: function updateFranchiseOwnerOrganizationLinks(_ref, franchiseOwnerOrganizationLinks) {
      var commit = _ref.commit;
      commit(FRANCHISE_OWNER_ACTIONS.updateFranchiseOwnerOrganizationLinks, franchiseOwnerOrganizationLinks);
    }
  },
  mutations: {
    updateFranchiseOwnerOrganizationLinks: function updateFranchiseOwnerOrganizationLinks(state, franchiseOwnerOrganizationLinks) {
      state.franchiseOwnerOrganizationLinks = franchiseOwnerOrganizationLinks;
    }
  }
};
export default FRANCHISE_OWNER_STORE;