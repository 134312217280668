import PERSONAL_CLIENT_ACTIONS from '@/identity_domain/vuex/personal_client/personal-client-actions';
const PERSONAL_CLIENT_STORE = {
  state: () => ({
    personalClientOrganizationLinks: []
  }),
  getters: {
    personalClientOrganizationLinks(state) {
      return state.personalClientOrganizationLinks;
    }
  },
  actions: {
    updatePersonalClientOrganizationLinks({
      commit
    }, personalClientOrganizationLinks) {
      commit(PERSONAL_CLIENT_ACTIONS.updatePersonalClientOrganizationLinks, personalClientOrganizationLinks);
    }
  },
  mutations: {
    updatePersonalClientOrganizationLinks(state, personalClientOrganizationLinks) {
      state.personalClientOrganizationLinks = personalClientOrganizationLinks;
    }
  }
};
export default PERSONAL_CLIENT_STORE;