import TaskAuthentication from '@/event_management_domain_new/coopax_tasks/authentications/task-authentication';
export default [{
  path: '/exhibitor-tasks',
  name: 'exhibitor-tasks',
  component: () => import('@/event_management_domain_new/coopax_tasks/exhibitor_tasks/ExhibitorAllTaskContainer.vue'),
  meta: {
    authentication: TaskAuthentication
  },
  props: true
}, {
  path: '/exhibitor-tasks/:taskId',
  name: 'exhibitor-task',
  component: () => import('@/event_management_domain_new/coopax_tasks/exhibitor_tasks/DashboardExhibitorTask.vue'),
  props: true,
  meta: {
    authentication: TaskAuthentication
  }
}];