import Languages from '@/crm_domain/languages/constants/languages-const';
import head from 'lodash/head';
const MultiLanguageMethodObject = {
  getTextFromMultiLanguageData(texts, langCode) {
    if (!texts || texts.length === 0) {
      return '';
    }
    const multiLanguageData = head(texts.filter(text => text.languageCode === langCode));

    // return correct data of current language
    // If not present, return english data (always exist)
    if (multiLanguageData) {
      return multiLanguageData.data;
    }
    const englishData = head(texts.filter(text => text.languageCode === Languages.EN.value));
    if (englishData) {
      return englishData.data;
    }
    return '';
  }
};
export default MultiLanguageMethodObject;