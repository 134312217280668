import axios from 'axios';
import 'url-search-params-polyfill';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import metaInfo from 'vue-meta-info';
import App from './App.vue';
import { dateTimeFormats } from '@/core/config/i18n';
import './core/config/i18n';
import './core/config/axios';
import './core/config/components';
import './core/config/global-filters';
import './core/config/global-mixin';
import './core/config/google-maps';
import './core/config/light-gallery';
import './core/config/line-clamp';
import './core/config/portal';
import './core/config/sanitzeHtml';
import './core/config/uuid';
import vuetify from './core/config/vuetify';
import store from './core/config/vuex';
import './core/config/wysiwyg';
import router from './core/routes/router';
Vue.config.productionTip = false;
Vue.use(metaInfo);
async function loadLocaleMessages() {
  const baseURL = process.env.VUE_APP_API_BASE_URL;
  const response = await axios.get(`${baseURL}/v1/translations/i18n-translations/languages`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'app-initial-request': 'i18n-translation'
    }
  });
  return response.data;
}
loadLocaleMessages().then(response => {
  const messages = {
    en: JSON.parse(response.en),
    'en-US': JSON.parse(response.en),
    br: JSON.parse(response.br),
    es: JSON.parse(response.es)
  };
  const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    dateTimeFormats,
    silentTranslationWarn: true
  });
  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
    i18n,
    metaInfo,
    mounted() {
      setTimeout(() => {
        document.dispatchEvent(new Event('app-rendered'));
      }, 5000);
    }
  }).$mount('#app');
});