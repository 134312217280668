import store from '@/core/config/vuex';
import axios from 'axios';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
import UserInformationApis from '@/crm_domain/users/apis/user-information-apis';
const UserInformationAuthorization = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    await axios.head(UserInformationApis.GET_USER_INFO, {
      params: {
        username: encodeURIComponent(to.params.username),
        organizationId: store.getters.currentOrganizationId
      }
    }).then(() => {
      if (next) {
        next();
      }
    }).catch(errors => {
      if (errors.response && errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default UserInformationAuthorization;