import bus from '@/core/event-bus';
import router from '@/core/routes/router';
const GlobalAuthorization = {
  goHomeDueToNoPermissionToAccess() {
    bus.$emit('app-notification', 'constant.not_authorized#error', 'error');
    router.push({
      name: 'home'
    });
  }
};
export default GlobalAuthorization;