import axios from 'axios';
import store from '@/core/config/vuex';
import LocationAPIs from '@/venue_management_domain/venue_information/apis/location-apis';
import httpStatus from '@/core/constants/http-status';
import GlobalAuthorization from '@/core/authorizations/global-authorization';
const VENUE_AUTHORIZATION = {
  // eslint-disable-next-line no-unused-vars
  async authorize({
    to,
    from,
    next
  }) {
    const currentOrganizationId = store.getters.currentOrganizationId;
    await axios.head(LocationAPIs.FETCH_LOCATION(currentOrganizationId, to.params.locationId)).then(() => {
      next();
    }).catch(errors => {
      if (errors && errors.response && errors.response.status === httpStatus.FORBIDDEN) {
        GlobalAuthorization.goHomeDueToNoPermissionToAccess();
      }
    });
  }
};
export default VENUE_AUTHORIZATION;