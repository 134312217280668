import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import MultiLanguageMethodObject from '@/core/method_objects/multi-language-method-object';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import isElement from 'lodash/isElement';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import unset from 'lodash/unset';
import orderBy from 'lodash/orderBy';
import format from 'date-fns/format';
import bus from '@/core/event-bus';
import ImageSizes from '@/core/constants/images-size';
import VuexStore from '@/core/config/vuex';
import { brDateFormat, brDateTimeFormat, enDateFormat, enDateTimeFormat, nlDateFormat, nlDateTimeFormat } from '@/core/constants/date-constants';
import Languages from '@/crm_domain/languages/constants/languages-const';
import { mapGetters } from 'vuex';
import AUTHENTICATION_GETTERS from '@/identity_domain/authentication/vuex/authentication-getters';
export default Vue.mixin({
  data() {
    return {
      languages: Languages.getOriginalList(),
      imgSizes: ImageSizes,
      isProduction: process.env.VUE_APP_PRODUCTION === 'true',
      shouldShowOnlyInProductionServer: process.env.VUE_APP_ENVIRONMENT === 'Live',
      shouldShowOnlyInDemoServer: process.env.VUE_APP_ENVIRONMENT === 'demo',
      showExclVatInApp: process.env.VUE_APP_SHOW_EXCL_VAT === 'true'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    userSetting: AUTHENTICATION_GETTERS.userSettings,
    languageCode: AUTHENTICATION_GETTERS.userLanguage,
    isTranslatorMode: AUTHENTICATION_GETTERS.isTranslatorMode
  })), {}, {
    dateTimeFormat() {
      const dateFormat = VuexStore.getters.userSettings && VuexStore.getters.userSettings.dateFormat || 'en-US';
      if (dateFormat === 'en-US') {
        return enDateTimeFormat;
      } else if (dateFormat === 'nl') {
        return nlDateTimeFormat;
      }
      return brDateTimeFormat;
    },
    dateFormat() {
      const dateFormat = VuexStore.getters.userSettings && VuexStore.getters.userSettings.dateFormat || 'en-US';
      if (dateFormat === 'en-US') {
        return enDateFormat;
      } else if (dateFormat === 'nl') {
        return nlDateFormat;
      }
      return brDateFormat;
    }
  }),
  methods: {
    cloneDeep,
    isEmpty,
    isNil,
    editTranslation() {
      if (!this.isTranslatorMode) {
        return;
      }
      this.$_makeKeyOriginal();
      setTimeout(() => {
        const transKey = this.$el.innerText.split('\n')[1].toLowerCase();
        bus.$emit('i18n#translate#command', transKey);
      }, 0);
    },
    editInputTranslation() {
      if (!this.isTranslatorMode) {
        return;
      }
      this.$_makeKeyOriginal();
      setTimeout(() => {
        const transKey = this.$attrs.label || this.$attrs.placeholder;
        bus.$emit('i18n#translate#command', transKey);
      }, 0);
    },
    $_makeKeyOriginal() {
      this.$_overrideTAndForceUpdate(this.$parent);
    },
    $_overrideTAndForceUpdate(component, levelsDeep = 10) {
      if (!component || levelsDeep === 0) {
        return;
      }
      component.$t = key => {
        return key;
      };
      component.$forceUpdate();
      this.$_overrideTAndForceUpdate(component.$parent, levelsDeep - 1);
    },
    $_globalMixin_disableRootScrolling() {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    },
    $_globalMixin_enableRootScrolling() {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    },
    getProductionApiUri(apiUri) {
      return apiUri.replace(process.env.VUE_APP_API_BASE_URL, process.env.VUE_APP_PRODUCTION_API_BASE_URL);
    },
    getDemoApiUri(apiUri) {
      return apiUri.replace(process.env.VUE_APP_API_BASE_URL, process.env.VUE_APP_DEMO_API_BASE_URL);
    },
    showLoadingOverlay(loadingEvent = this.loadingEvent) {
      if (loadingEvent) {
        bus.$emit(`loading:${loadingEvent}`, true);
      }
    },
    hideLoadingOverlay(loadingEvent = this.loadingEvent) {
      if (loadingEvent) {
        bus.$emit(`loading:${loadingEvent}`, false);
      }
    },
    closeAppNotification() {
      bus.$emit('close-app-notification');
    },
    showAppNotification(message, type = 'success') {
      if (message) {
        bus.$emit('app-notification', this.$t(message), type);
      }
    },
    showSuccessNotification(message = this.$t('global.saved#toast_msg')) {
      this.showAppNotification(message, 'success');
    },
    showWarningNotification(message) {
      this.showAppNotification(message, 'warning');
    },
    showErrorNotification(message = this.$t('error.generic')) {
      this.showAppNotification(message, 'error');
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    scrollToElement(el, block = 'end') {
      if (isElement(el)) {
        el.scrollIntoView({
          block,
          behavior: 'smooth'
        });
      }
    },
    /**
     *
     * @param originalUrl is url when uploading image
     * @param size is the size we display in view
     * @returns {string} The exactly image size and format get from cloudinary
     */
    getImageUrl(originalUrl, size = {
      width: 0,
      height: 0
    }) {
      if (!originalUrl || typeof originalUrl !== 'string') {
        return '';
      }
      if (originalUrl.indexOf('gif') >= 0 || !originalUrl.includes('cloudinary.com')) {
        return originalUrl;
      }
      const [baseUrl, imageLink] = originalUrl.split('upload');
      const imageName = imageLink.split('.')[0];
      return `${baseUrl}upload/c_fill,g_auto,w_${size.width},h_${size.height}${imageName}.jpeg`;
    },
    formatDate(dateTimeStr) {
      if (!dateTimeStr) {
        return '';
      }
      return format(dateTimeStr, this.dateFormat);
    },
    /**
     *
     * @param item
     * @param inclVatProperty
     * @returns {*}  price without vat
     */
    adaptPriceForVat(item, inclVatProperty = 'price') {
      return get(item, inclVatProperty);
    },
    getTranslatedHeaders(translationMethod, headers) {
      const modifyingHeaders = this.cloneDeep(headers);
      return modifyingHeaders.map(header => {
        const transKey = header.text;

        // remove the text property so it's not a string anymore - just a getter
        unset(header, 'text');
        const translatedHeader = _objectSpread({
          get text() {
            // $_transKey is private property for translation key
            // when there is no setter was ever called before use the original transKey passed first time
            // we use the transKey when the transKey was changed during running time (for any reason)
            // we use setter and getter the transKey
            return this.$_transKey && translationMethod.t(this.$_transKey) || translationMethod.t(transKey);
          },
          set text(transKey) {
            this.$_transKey = transKey;
          }
        }, header);
        translatedHeader.text = transKey;
        return translatedHeader;
      });
    },
    /**
     * As common rules, PO want to have a custom sorting that empty value is always in the bottom
     * So we make this function for single sorting data table.
     * @param items
     * @param sortBy
     * @param sortDesc
     * @returns {*[]}
     */
    dataTableCustomSorting(items, sortBy, sortDesc) {
      const sortOrder = sortDesc[0] ? ['desc'] : ['asc'];
      const emptyValueItems = items.filter(item => !item[sortBy]);
      const hasValueItems = orderBy(items.filter(item => item[sortBy]), sortBy, sortOrder);
      return [...hasValueItems, ...emptyValueItems];
    },
    $_globalMixin_getTextFromMultiLanguageData(texts, langCode = this.languageCode) {
      return MultiLanguageMethodObject.getTextFromMultiLanguageData(texts, langCode);
    }
  }
});