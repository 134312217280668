import VENUE_MANAGER_ACTIONS from '@/identity_domain/vuex/venue_manager/venue-manager-actions';
const VENUE_MANAGER_STORE = {
  state: () => ({
    venueManagerOrganizationLinks: []
  }),
  getters: {
    venueManagerOrganizationLinks(state) {
      return state.venueManagerOrganizationLinks;
    }
  },
  actions: {
    updateVenueManagerOrganizationLinks({
      commit
    }, venueManagerOrganizationLinks) {
      commit(VENUE_MANAGER_ACTIONS.updateVenueManagerOrganizationLinks, venueManagerOrganizationLinks);
    }
  },
  mutations: {
    updateVenueManagerOrganizationLinks(state, venueManagerOrganizationLinks) {
      state.venueManagerOrganizationLinks = venueManagerOrganizationLinks;
    }
  }
};
export default VENUE_MANAGER_STORE;