import EPS_TO_COMPARE_ACTIONS from '@/event_booking_domain/event_planner_compare/vuex/eps-to-compare-actions';
const EPS_TO_COMPARE_STORE = {
  state: () => ({
    comparingEventPlannerIds: []
  }),
  mutations: {
    updateComparingEventPlannerIds(state, ids) {
      state.comparingEventPlannerIds = ids;
    },
    clearComparingEventPlannerIds(state) {
      state.comparingEventPlannerIds = [];
    }
  },
  getters: {
    comparingEventPlannerIds(state) {
      return state.comparingEventPlannerIds;
    }
  },
  actions: {
    updateComparingEventPlannerIds({
      commit
    }, ids) {
      commit(EPS_TO_COMPARE_ACTIONS.updateComparingEventPlannerIds, ids);
    },
    clearComparingEventPlannerIds({
      commit
    }) {
      commit(EPS_TO_COMPARE_ACTIONS.clearComparingEventPlannerIds);
    }
  }
};
export default EPS_TO_COMPARE_STORE;