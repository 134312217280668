import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import franchiseOwnerProfileRoutes from '@/crm_domain/organization/routes/franchise-owner-profile-routes';
import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
import CalendarAuthentication from '@/property_management_domain/calendar/authentications/calendar-authentication';
import CalendarAuthorization from '@/property_management_domain/calendar/authorizations/calendar-authorization';
import VenueAuthentication from '@/venue_management_domain/authentications/venue-authentication';
export default [{
  path: '/franchise-owner-dashboard',
  name: 'franchise-owner-dashboard',
  component: function component() {
    return import('@/event_management_domain_new/franchise_owner_dashboard/FranchiseOwnerDashboard.vue');
  },
  meta: {
    roles: [OrganizationTypes.FRANCHISE_OWNER.value]
  }
}, {
  path: '/franchise-owner-area-calendar',
  name: 'franchise-owner-area-calendar',
  component: function component() {
    return import('@/property_management_domain/calendar/components/FranchiseOwnerAreaCalendarContainer.vue');
  },
  meta: {
    roles: [OrganizationTypes.FRANCHISE_OWNER.value],
    authentication: CalendarAuthentication,
    authorization: CalendarAuthorization,
    unsupportedMobile: true
  }
}, {
  path: '/franchise-owner-venues',
  name: 'franchise-owner-venues',
  component: function component() {
    return import('@/venue_management_domain/franchise_owner_venues/components/FranchiseOwnerVenueCollectionContainer.vue');
  },
  meta: {
    roles: [OrganizationTypes.FRANCHISE_OWNER.value],
    authentication: VenueAuthentication,
    authorization: VenueAuthentication,
    unsupportedMobile: true
  }
}].concat(_toConsumableArray(franchiseOwnerProfileRoutes));