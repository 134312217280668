import ITEM_NAVIGATION_ACTIONS from '@/core/item_navigation/store/item-navigation-actions';
const ITEM_NAVIGATION_STORE = {
  state: () => ({
    /**
     * list language of admin that is active
     */
    itemRoutes: [],
    /**
     * API uri to fetch list data
     */
    fetchingListUri: '',
    /**
     * Filter, sort and pagination information
     */
    pageRequest: {},
    /**
     * Total page of a table
     */
    totalPage: 1,
    /**
     * Total items in the table
     */
    totalElements: 1,
    /**
     * the method that is use to build the 'editing item route' from item data
     */
    buildingNavigationMethodWrapper: {},
    /**
     * path to the item list
     */
    pagePathOfListItem: '',
    /**
     * we use "saveSuccessfully" to control handling actions after saving
     */
    saveSuccessfully: false,
    /**
     * Should go to next item after saving
     */
    shouldGoToNextItemAfterSaving: false,
    /**
     * Should add another item after saving
     */
    shouldAddAnotherItemAfterSaving: false,
    /**
     * Should go back to list item after saving
     */
    shouldGoBackToListItemAfterSaving: false
  }),
  mutations: {
    updateItemRoutes(state, itemRoutes) {
      state.itemRoutes = itemRoutes;
    },
    updateFetchingListUri(state, fetchingListUri) {
      state.fetchingListUri = fetchingListUri;
    },
    updatePageRequest(state, pageRequest) {
      state.pageRequest = pageRequest;
    },
    updateTotalPage(state, totalPage) {
      state.totalPage = totalPage;
    },
    updateBuildingNavigationMethodWrapper(state, updateBuildingNavigationMethodWrapper) {
      state.buildingNavigationMethodWrapper = updateBuildingNavigationMethodWrapper;
    },
    updatePagePathOfListItem(state, pagePathOfListItem) {
      state.pagePathOfListItem = pagePathOfListItem;
    },
    updateTotalElements(state, totalElements) {
      state.totalElements = totalElements;
    },
    updateSaveSuccessfullyAfterSaving(state, saveSuccessfully) {
      state.saveSuccessfully = saveSuccessfully;
    },
    updateShouldGoBackToListItemAfterSaving(state, shouldGoBackToListItemAfterSaving) {
      state.shouldGoBackToListItemAfterSaving = shouldGoBackToListItemAfterSaving;
    },
    updateShouldAddAnotherItemAfterSaving(state, shouldAddAnotherItemAfterSaving) {
      state.shouldAddAnotherItemAfterSaving = shouldAddAnotherItemAfterSaving;
    },
    updateShouldGoToNextItemAfterSaving(state, shouldGoToNextItemAfterSaving) {
      state.shouldGoToNextItemAfterSaving = shouldGoToNextItemAfterSaving;
    }
  },
  getters: {
    getItemRoutes(state) {
      return state.itemRoutes;
    },
    getFetchingListUri(state) {
      return state.fetchingListUri;
    },
    getPageRequest(state) {
      return state.pageRequest;
    },
    getTotalPage(state) {
      return state.totalPage;
    },
    getBuildingNavigationMethodWrapper(state) {
      return state.buildingNavigationMethodWrapper;
    },
    getPagePathOfListItem(state) {
      return state.pagePathOfListItem;
    },
    getTotalElements(state) {
      return state.totalElements;
    },
    getSaveSuccessfully(state) {
      return state.saveSuccessfully;
    },
    shouldGoToNextItemAfterSaving(state) {
      return state.shouldGoToNextItemAfterSaving;
    },
    shouldAddAnotherItemAfterSaving(state) {
      return state.shouldAddAnotherItemAfterSaving;
    },
    shouldGoBackToListItemAfterSaving(state) {
      return state.shouldGoBackToListItemAfterSaving;
    }
  },
  actions: {
    /**
     * update all item routes of current list
     * @param commit
     * @param itemRoutes
     */
    updateItemRoutes({
      commit
    }, itemRoutes) {
      commit(ITEM_NAVIGATION_ACTIONS.updateItemRoutes, itemRoutes);
    },
    updateFetchingListUri({
      commit
    }, fetchingListUri) {
      commit(ITEM_NAVIGATION_ACTIONS.updateFetchingListUri, fetchingListUri);
    },
    updatePageRequest({
      commit
    }, pageRequest) {
      commit(ITEM_NAVIGATION_ACTIONS.updatePageRequest, pageRequest);
    },
    updateTotalPage({
      commit
    }, totalPage) {
      commit(ITEM_NAVIGATION_ACTIONS.updateTotalPage, totalPage);
    },
    updateBuildingNavigationMethodWrapper({
      commit
    }, buildingNavigationMethodWrapper) {
      commit(ITEM_NAVIGATION_ACTIONS.updateBuildingNavigationMethodWrapper, buildingNavigationMethodWrapper);
    },
    updatePagePathOfListItem({
      commit
    }, pagePathOfListItem) {
      commit(ITEM_NAVIGATION_ACTIONS.updatePagePathOfListItem, pagePathOfListItem);
    },
    updateTotalElements({
      commit
    }, totalElements) {
      commit(ITEM_NAVIGATION_ACTIONS.updateTotalElements, totalElements);
    },
    updateSaveSuccessfullyAfterSaving({
      commit
    }, saveSuccessfully) {
      commit(ITEM_NAVIGATION_ACTIONS.updateSaveSuccessfullyAfterSaving, saveSuccessfully);
    },
    updateShouldGoBackToListItemAfterSaving({
      commit
    }, shouldGoBackToListItemAfterSaving) {
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldGoBackToListItemAfterSaving, shouldGoBackToListItemAfterSaving);
    },
    updateShouldAddAnotherItemAfterSaving({
      commit
    }, shouldAddAnotherItemAfterSaving) {
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldAddAnotherItemAfterSaving, shouldAddAnotherItemAfterSaving);
    },
    updateShouldGoToNextItemAfterSaving({
      commit
    }, shouldGoToNextItemAfterSaving) {
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldGoToNextItemAfterSaving, shouldGoToNextItemAfterSaving);
    }
  }
};
export default ITEM_NAVIGATION_STORE;